import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Cup, CupFilterConfig, CupFilterType, CupType } from '../../../types/cup';
import AddIcon from '@mui/icons-material/Add';
import { CupFilterForm } from '../CupFilterForm';
import { CupTabularDataFilterForm } from './CupTabularDataFilterForm';
import SectionTable from '../../../components/SectionTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteCupFilterMutation } from '../../../state/api';

interface CupFilterEditorProps {
  cup: Cup;
}

export const CupFilterEditor = ({ cup }: CupFilterEditorProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<CupFilterConfig>(null);
  const [deleteFilterId, setDeleteFilterId] = useState<string>('');
  const [deleteCupFilter] = useDeleteCupFilterMutation();
  const [infoMessage, setInfoMessage] = useState<string>('');

  const editForm = (filter: CupFilterConfig) => {
    setSelectedFilter(filter);
    setIsFormOpen(true);
  };

  const handleFormClose = () => {
    setSelectedFilter(null);
    setIsFormOpen(false);
  };

  const handleDeleteFilterShowDialog = (event, filterId: string) => {
    event.stopPropagation();
    setDeleteFilterId(filterId);
  };

  const handleDeleteFilterCancel = () => {
    setDeleteFilterId('');
  };

  const handleRemoveInfoMessage = () => {
    setInfoMessage('');
  };

  const handleDeleteFilter = () => {
    try {
      deleteCupFilter({
        filterId: deleteFilterId,
        cupId: cup.id
      }).unwrap();
      setInfoMessage('Cup filter deleted.');
    } catch (error) {
      console.log('Delete Cup filter failed.');
    } finally {
      setDeleteFilterId('');
    }
  };

  const filterTypeTitle = (filterType: CupFilterType): string => {
    switch (filterType) {
      case CupFilterType.LABEL_FILTER:
        return 'Label Filter';
      case CupFilterType.FILENAME_FILTER:
        return 'Filename Filter';
    }
  };

  const filterValue = (filter: CupFilterConfig) => {
    if (filter && filter.filterType == CupFilterType.FILENAME_FILTER) {
      return filter.parameters['regex'];
    }
    return '';
  };

  return (
    <Box>
      {!isFormOpen && (
        <SectionTable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="right">
                <IconButton color="primary" edge="start" onClick={() => setIsFormOpen(true)} size="medium">
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cup.filters &&
              cup.filters.map((filter) => (
                <TableRow key={filter.filterId} hover onClick={() => editForm(filter)}>
                  <TableCell>{filter.name}</TableCell>
                  <TableCell>{filterTypeTitle(filter.filterType)}</TableCell>
                  <TableCell>{filterValue(filter)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                      <IconButton aria-label="delete" onClick={(e) => handleDeleteFilterShowDialog(e, filter.filterId)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </SectionTable>
      )}
      {isFormOpen && (
        <Box>
          {cup.type == CupType.TABULAR && (
            <CupTabularDataFilterForm
              cupId={cup.id}
              onCancel={handleFormClose}
              onCompleted={handleFormClose}
              filterConfig={selectedFilter}
            />
          )}
          {cup.type == CupType.EXTRACTION && (
            <CupFilterForm
              cupId={cup.id}
              onCancel={handleFormClose}
              onCompleted={handleFormClose}
              filterConfig={selectedFilter}
            />
          )}
        </Box>
      )}
      <Dialog fullScreen={fullScreen} open={deleteFilterId.length > 0} onClose={handleDeleteFilterCancel}>
        <DialogTitle>{'Delete Filter'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this filter?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteFilterCancel}>No</Button>
          <Button onClick={() => handleDeleteFilter()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={infoMessage.length > 0}
        autoHideDuration={6000}
        message={infoMessage}
        onClose={handleRemoveInfoMessage}
      />
    </Box>
  );
};
