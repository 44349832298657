import React from 'react';
import { Box, Container, Fab, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../../state/authSlice';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import Logo from '../../components/Logo';
import { config } from '../../config';

const Login = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  function makeCallbackUrl(provider: string): string {
    const url = location.protocol + '//' + location.host + '/';
    const path = `/api/auth/${provider}/login?redirectUrl=${url}`;
    return config.apiUrl + path;
  }

  function handleGoogleSignIn() {
    setTimeout(() => {
      window.location.href = makeCallbackUrl('google');
    }, 0);
  }

  function handleAppleSignIn() {
    setTimeout(() => {
      window.location.href = makeCallbackUrl('apple');
    }, 0);
  }

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Box component="main" sx={{ p: 3, width: '100%' }}>
      <Logo />
      <Container maxWidth="xs">
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            padding: '2rem',
            mt: 10
          }}
        >
          <LockOutlinedIcon fontSize={'large'} />

          <Typography variant="caption">Login with</Typography>

          <Fab variant="extended" onClick={handleGoogleSignIn} sx={{ width: '100%' }}>
            <GoogleIcon sx={{ mr: 1 }} />
            Google
          </Fab>

          <Fab variant="extended" onClick={handleAppleSignIn} sx={{ width: '100%' }}>
            <AppleIcon sx={{ mr: 1 }} />
            Apple
          </Fab>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
