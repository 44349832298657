import React, { useState } from 'react';
import { TextField, Button, Box, Typography, InputLabel, Select, MenuItem } from '@mui/material';
import { usePutCupFilterMutation } from '../../state/api';
import { CupFilterConfig, CupFilterType } from '../../types/cup';

export interface CupFilterFormProps {
  cupId: string;
  filterConfig?: CupFilterConfig;
  onCompleted?: () => void;
  onCancel?: () => void;
}

interface CupFilterFormData {
  name: string;
  filterType: CupFilterType;
  documentType: string;
}

export const CupFilterForm = ({ cupId, filterConfig, onCompleted, onCancel }: CupFilterFormProps) => {
  const isCreateNewMode = () => {
    return !filterConfig;
  };

  const [formData, setFormData] = useState<CupFilterFormData>(
    isCreateNewMode()
      ? {
          name: '',
          filterType: CupFilterType.LABEL_FILTER,
          documentType: 'Consultant Agreement'
        }
      : {
          name: filterConfig.name,
          filterType: filterConfig.filterType,
          documentType: filterConfig.parameters['labelValue']
        }
  );

  const [putCupFilter] = usePutCupFilterMutation();

  const getFilterParameters = () => {
    const parameters: Record<string, string> = {};
    parameters['labelId'] = '39e176c3-4261-4a7a-b635-7bf78de60868';
    parameters['labelType'] = 'LabelDocument';
    parameters['labelValue'] = formData.documentType;

    return parameters;
  };

  const getFormTitle = () => {
    if (isCreateNewMode()) {
      return 'Create Filter';
    } else {
      return 'Edit Filter';
    }
  };

  const handleSubmit = async () => {
    try {
      await putCupFilter({
        filterId: filterConfig && filterConfig.filterId ? filterConfig.filterId : null,
        cupId: cupId,
        name: formData.name,
        filterType: formData.filterType,
        parameters: getFilterParameters(),
        createdAt: filterConfig && filterConfig.createdAt ? filterConfig.createdAt : null
      }).unwrap();

      if (onCompleted) {
        onCompleted();
      }
    } catch (error) {
      console.error('Failed to save form data', error);
    }
  };

  const handleCancel = async () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldName = name || e.target.getAttribute('name');

    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ minWidth: 500, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" component="div">
          {getFormTitle()}
        </Typography>
        <Typography variant="body2" component="div"></Typography>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          required={true}
          value={formData.name}
          onChange={handleChange}
          type={'text'}
        />
        <InputLabel id="filterTypeLabel">Filter Type</InputLabel>
        <Select
          labelId="filterTypeLabel"
          name="filterType"
          value={formData.filterType}
          label="Filter Type"
          onChange={handleChange}
        >
          <MenuItem value={CupFilterType.LABEL_FILTER}>Document Type Filter</MenuItem>
        </Select>
        <InputLabel id="documentTypeLabel">Document Type</InputLabel>
        <Select
          labelId="documentTypeLabel"
          name="documentType"
          value={formData.documentType}
          label="Document Type"
          onChange={handleChange}
        >
          <MenuItem value={'Consultant Agreement'}>Consultant Agreement</MenuItem>
          <MenuItem value={'Employment Agreement'}>Employment Agreement</MenuItem>
          <MenuItem value={'Supply Agreement'}>Supply Agreement</MenuItem>
        </Select>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" type="button" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};
