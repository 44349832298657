enum LabelType {
  ENTITY = 'ENTITY'
}

enum LabelCategory {
  STANDARD = 'STANDARD'
}

enum SystemLabels {
  DocumentId = 'ac345e8c-107d-4561-8cc6-e5aced2a2932'
}

interface Label extends LabelAlias {
  type: LabelType;
  description: LabelDescription;
}

interface LabelDescription {
  title: string;
  language: string;
  description: string;
}

interface LabelParameters extends Label {
  parameterIds: string[];
  fulfilled: boolean;
}

interface LabelMapping {
  label: Label;
  name: string;
}

interface LabelAlias {
  labelId: string;
  name: string;
}

interface LabelCreate {
  title: string;
  description: string;
  language: string;
}

interface LabelGet {
  category?: LabelCategory;
  search?: string;
  language?: string;
}

export {
  Label,
  LabelParameters,
  LabelCategory,
  LabelDescription,
  LabelType,
  LabelMapping,
  LabelAlias,
  LabelCreate,
  LabelGet,
  SystemLabels
};
