import React from 'react';
import Box from '@mui/material/Box';
import ProgressCircle from './ProgressCircle';

interface MainHeaderProps {
  children: React.ReactNode;
  centered?: boolean;
  overflow?: string;
  loading?: boolean;
}

export default function SectionBody({ children, centered = false, overflow = 'hidden', loading }: MainHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: 2,
        overflow: overflow,
        ...(centered && {
          alignItems: 'center'
        })
      }}
    >
      {loading ? <ProgressCircle /> : children}
    </Box>
  );
}
