import DeleteIcon from '@mui/icons-material/Delete';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/system';
import dayjs from 'dayjs';
import localeEn from 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionTable from '../../components/SectionTable';
import { useDeleteDocumentByIdMutation } from '../../state/api';
import { DocumentId, StandardLabel, TextExtraction } from '../../types/annotation';
import { DocumentDetails, SortOrder } from '../../types/document';
import { getShortenedFilename } from '../../utils/string.utils';
import { DocumentWithExtractions } from './Documents';

interface DocumentListProps {
  isLoading: boolean;
  documents: DocumentWithExtractions[];
  getExtractionValue: (documentId: DocumentId, label: StandardLabel) => string;
  sortedBy: string;
  sortOrder: SortOrder;
  onChangeSortOrder: () => void;
}

const DocumentsOverview = ({
  documents,
  isLoading,
  getExtractionValue,
  sortedBy,
  sortOrder,
  onChangeSortOrder
}: DocumentListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [deleteDocument] = useDeleteDocumentByIdMutation();
  const [documentToDelete, setDocumentToDelete] = useState<DocumentWithExtractions | undefined>(undefined);
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const hasLocation = (text: DocumentDetails) => {
    return text.parties.some((t) => t.location && t.location.lat > 0);
  };

  const countKpis = (extractions: TextExtraction[]): number => {
    return extractions ? extractions.filter((extraction: TextExtraction) => extraction.labelName === 'Kpi').length : 0;
  };

  const handleChangeSortOrder = () => {
    onChangeSortOrder();
  };

  const handleShowDeleteDialog = (event, doc: DocumentWithExtractions) => {
    event.stopPropagation();
    setDocumentToDelete(doc);
  };

  const handleDeleteCancel = () => {
    setDocumentToDelete(undefined);
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteDocument = async () => {
    try {
      await deleteDocument(documentToDelete.document.id).unwrap();
      setDeleteMessage(`Document deleted: ${documentToDelete.document.name}`);
      setDocumentToDelete(undefined);
    } catch (error) {
      console.error('Failed to delete document: ', error);
    }
  };

  const daysago = (postDate: Date) => {
    dayjs.extend(relativeTime).locale(localeEn);
    return dayjs(postDate).fromNow();
  };

  return (
    <>
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={sortOrder}>
              <TableSortLabel active={sortedBy === 'createdAt'} direction={sortOrder} onClick={handleChangeSortOrder}>
                Uploaded
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Name</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Counterpart</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Start Date</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>End Date</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Governing Law</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Pages</TableCell>
            <TableCell>
              <MapIcon />
            </TableCell>
            <TableCell>KPIs</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            documents &&
            documents.map((docWithExtractions) => {
              const doc = docWithExtractions.document;
              return (
                <TableRow key={doc.id} hover onClick={() => navigate(`/documents/${doc.id}`)}>
                  <TableCell>{daysago(doc.updatedAt)}</TableCell>
                  <TableCell>{getShortenedFilename(doc.name, 30)}</TableCell>
                  <TableCell>{getExtractionValue(doc.id, StandardLabel.Supplier)}</TableCell>
                  <TableCell>{getExtractionValue(doc.id, StandardLabel.StartDate)}</TableCell>
                  <TableCell>{getExtractionValue(doc.id, StandardLabel.TerminationDate)}</TableCell>
                  <TableCell>{getExtractionValue(doc.id, StandardLabel.GoverningLaw)}</TableCell>
                  <TableCell>{doc.pages}</TableCell>
                  <TableCell>
                    {hasLocation(doc) ? <LocationOnIcon color="primary" /> : <LocationOffIcon color="disabled" />}
                  </TableCell>
                  <TableCell>{countKpis(docWithExtractions.extractions)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                      <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, docWithExtractions)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </SectionTable>

      <Dialog fullScreen={fullScreen} open={documentToDelete !== undefined} onClose={handleDeleteCancel}>
        <DialogTitle id="responsive-dialog-title">{'Delete document'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete {documentToDelete?.document.name}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleDeleteCancel} color="inherit">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteDocument()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={6000}
        message={deleteMessage}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
};

export default DocumentsOverview;
