import React from 'react';
import { CssBaseline } from '@mui/material';
import * as reactRouterDom from 'react-router-dom';
import { Navigate, Route } from 'react-router-dom';
import { ColorModeProvider } from './theme-context';
import Login from './scenes/login/Login';
import Signup from './scenes/signup/Signup';
import TokenRoute from './components/TokenRoute';
import { SocketProvider } from './components/SocketContext';
import SiteDown from './scenes/error/SiteDown';
import { ProtectedLayout } from './scenes/layout/ProtectedLayout';
import Documents from './scenes/documents/Documents';
import Form from './scenes/form/Form';
import Cups from './scenes/cups/Cups';
import { CupCreateForm } from './scenes/cups/CupCreateForm';
import CupEditor from './scenes/cups/CupEditor';
import { MirrorEditor } from './scenes/mirror/MirrorEditor';
import Mirrors from './scenes/mirrors/Mirrors';
import { MirrorCreate } from './scenes/mirror/MirrorCreate';
import AdminChat from './scenes/admin/AdminChat';
import { WorkflowCreate } from './scenes/workflow/WorkflowCreate';
import { WorkflowView } from './scenes/workflow/WorkflowView';
import Workflows from './scenes/workflow/Workflows';
import Activation from './scenes/activation/Activation';
import AdminView from './scenes/admin/AdminView';
import { Dashboard } from './scenes/dashboard/Dashboard';
import DocumentView from './scenes/document/DocumentView';

function App() {
  const router = reactRouterDom.createBrowserRouter(
    reactRouterDom.createRoutesFromElements(
      <Route>
        <Route path="/error" element={<SiteDown />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/signup"
          element={
            <TokenRoute>
              <Signup />
            </TokenRoute>
          }
        />

        <Route path="/activation" element={<Activation />} />

        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Navigate to="/documents" replace />} />
          <Route path="/forms/:formId" element={<Form />} />
          <Route path="/documents">
            <Route path="" element={<Documents />} />
            <Route path=":documentId" element={<DocumentView />} />
          </Route>
          <Route path="/cups">
            <Route path="" element={<Cups />} />
            <Route path="add" element={<CupCreateForm />} />
            <Route path=":cupId" element={<CupEditor />} />
          </Route>
          <Route path="/workflows">
            <Route path="" element={<Workflows />} />
            <Route path="create" element={<WorkflowCreate />} />
            <Route path=":workflowInstanceId" element={<WorkflowView />} />
          </Route>
          <Route path="/mirrors">
            <Route path="" element={<Mirrors />} />
            <Route path="add" element={<MirrorCreate />} />
            <Route path=":mirrorId" element={<MirrorEditor />} />
          </Route>
          <Route path="/admin">
            <Route path="" element={<AdminView />} />
            <Route path="chat" element={<AdminChat />} />
          </Route>

          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Route>
    )
  );

  return (
    <SocketProvider>
      <ColorModeProvider>
        <CssBaseline />
        <reactRouterDom.RouterProvider router={router} />
      </ColorModeProvider>
    </SocketProvider>
  );
}

export default App;
