import React, { useEffect, useState } from 'react';
import { Box, Checkbox, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DocumentId, TextExtraction } from '../../types/annotation';
import SectionTable from '../../components/SectionTable';
import { findFirstTextExtractionByLabelName, normalisedExtractionValue } from '../../types/utils/extractions';
import CreateButton from '../../components/CreateButton';
import { useCreateCpmWorkflowMutation } from '../../state/api';
import { useNavigate } from 'react-router-dom';

interface DocumentKpiListProps {
  documentId: DocumentId;
  extractions: TextExtraction[];
}

interface Kpi {
  description: string;
  goal: string;
  limit: string;
  frequency: string;
  responsible: string;
  kpiType: string;
  monitoringMeeting: string;
  extractionId: string;
}

const DocumentKpiList = ({ documentId, extractions }: DocumentKpiListProps) => {
  const [kpis, setKpis] = useState<Kpi[]>([]);
  const [selectedKpis, setSelectedKpis] = useState<string[]>([]);
  const navigate = useNavigate();
  const [createCpmWorkflow] = useCreateCpmWorkflowMutation();

  const convertToKpi = (extraction: TextExtraction): Kpi => {
    const kpi = {
      description: normalisedExtractionValue(extraction),
      goal: normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'KpiGoal')),
      limit: normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'KpiLimit')),
      frequency: normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'KpiFrequency')),
      responsible: normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'KpiResponsible')),
      kpiType: normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'KpiType')),
      monitoringMeeting: normalisedExtractionValue(
        findFirstTextExtractionByLabelName(extraction, 'KpiMonitoringMeeting')
      ),
      extractionId: extraction.extractionId
    };

    return kpi;
  };

  const isSelected = (kpi: Kpi): boolean => {
    return selectedKpis.includes(kpi.description);
  };

  const onSelectKpi = (kpi: Kpi) => {
    if (isSelected(kpi)) {
      setSelectedKpis((cur) => cur.filter((k) => k !== kpi.description));
    } else {
      setSelectedKpis((cur) => [...cur, kpi.description]);
    }
  };

  const handleCreateWorkflow = async () => {
    const req = {
      documentId: documentId,
      kpis: kpis.filter((k) => selectedKpis.includes(k.description)).map((k) => k.extractionId)
    };
    createCpmWorkflow(req)
      .unwrap()
      .then((mirrorId) => {
        navigate(`/mirrors/${mirrorId}`, { replace: true });
      });
  };

  useEffect(() => {
    setKpis(
      extractions.filter((extraction) => extraction.labelName === 'Kpi').map((extraction) => convertToKpi(extraction))
    );
  }, [extractions]);

  return (
    <Box>
      <CreateButton title="Create Workflow" onClick={handleCreateWorkflow} disabled={selectedKpis.length === 0} />
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>KPI</TableCell>
            <TableCell>Goal and Description</TableCell>
            <TableCell>Limit</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Responsible</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Monitoring Meeting</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kpis.map((kpi, index) => (
            <TableRow key={index} hover>
              <TableCell>
                <Checkbox size="small" checked={isSelected(kpi)} onChange={() => onSelectKpi(kpi)} />
              </TableCell>
              <TableCell>{kpi.description}</TableCell>
              <TableCell>{kpi.goal}</TableCell>
              <TableCell>{kpi.limit}</TableCell>
              <TableCell>{kpi.frequency}</TableCell>
              <TableCell>{kpi.responsible}</TableCell>
              <TableCell>{kpi.kpiType}</TableCell>
              <TableCell>{kpi.monitoringMeeting}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </SectionTable>
    </Box>
  );
};

export default DocumentKpiList;
