interface ProcessDetails {
  processId: string;
  tenantId: string;
  started: string;
  name: string;
}

enum ProcessStatus {
  LOADING = 'LOADING',
  OK = 'OK'
}

export { ProcessDetails, ProcessStatus };
