import { Box, Breadcrumbs, CircularProgress, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SectionBody from '../../components/SectionBody';
import { useGetDocumentByIdQuery, useGetExtractionsByDocumentIdQuery } from '../../state/api';
import DocumentExtractionList from './DocumentExtractionList';
import DocumentKpiList from './DocumentKpiList';
import DocumentPreview from './DocumentPreview';

interface TabPanelProps {
  children?: React.ReactNode;
  active: boolean;
  value: TabValue;
}

type TabValue = 'preview' | 'extraction' | 'kpi';

function TabPanel(props: TabPanelProps) {
  const { children, active, value, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={!active}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      style={{ overflowY: 'auto' }}
      {...other}
    >
      <Box sx={{ pt: 1, display: active ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

const DocumentView = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { data: document, isLoading: isDocumentLoading } = useGetDocumentByIdQuery(documentId);
  const { data: annotations, isLoading: isAnnotationsLoading } = useGetExtractionsByDocumentIdQuery(documentId);

  const handleChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setActiveTab(newValue);
  };

  const breadcrumbs = [
    <Link variant={'h6'} underline="hover" key="1" color="inherit" href="#" onClick={() => navigate('/documents')}>
      Document
    </Link>,
    <Typography variant={'h6'} key="2" color="text.primary">
      {document ? document.details.name : ''}
    </Typography>
  ];

  if (isDocumentLoading || isAnnotationsLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <SectionBody>
        <>
          <Box>
            <Tabs value={activeTab} onChange={handleChange}>
              <Tab id="tab-preview" aria-controls="tabpanel-preview" label="Preview" />
              <Tab id="tab-extraction" aria-controls="tabpanel-extraction" label="Extractions" />
              <Tab id="tab-kpi" aria-controls="tabpanel-kpi" label="KPIs" />
            </Tabs>
          </Box>
          <TabPanel active={activeTab === 0} value="preview">
            <DocumentPreview document={document} annotations={annotations} />
          </TabPanel>
          <TabPanel active={activeTab === 1} value="extraction">
            <DocumentExtractionList extractions={annotations} />
          </TabPanel>
          <TabPanel active={activeTab === 2} value="kpi">
            <DocumentKpiList documentId={documentId} extractions={annotations} />
          </TabPanel>
        </>
      </SectionBody>
    </>
  );
};

export default DocumentView;
