import React from 'react';
import MapView, { MapPoint } from '../../../components/MapView';
import { Box } from '@mui/material';

interface MapProps {
  mapPoints: MapPoint<unknown>[];
}

const Map = ({ mapPoints }: MapProps) => {
  return (
    <Box>
      <MapView points={mapPoints} />
    </Box>
  );
};

export default Map;
