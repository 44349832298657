import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import HelpText from './HelpText';
import ProgressCircle from './ProgressCircle';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

export interface SectionPanelProps {
  title: string;
  subtitle?: string;
  help?: string;
  children: React.ReactNode;
  loading?: boolean;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const SectionPanel = ({ title, variant, subtitle, help, children, loading }: SectionPanelProps) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <Box sx={{ pt: '1rem', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography variant={variant ? variant : 'h6'}>{title}</Typography>
        {help && (
          <IconButton onClick={() => setShowHelp(!showHelp)}>
            <HelpIcon fontSize="small" color="info" />
          </IconButton>
        )}
      </Box>
      {subtitle && <Typography variant={'caption'}>{subtitle}</Typography>}
      {showHelp && <HelpText text={help} />}
      {loading ? <ProgressCircle /> : children}
    </Box>
  );
};
