import React, { useEffect, useState } from 'react';
import { ChatMessage } from '../../types/chat';
import { Box, Button, TextField, Typography } from '@mui/material';
import { usePutChatMessageMutation } from '../../state/api';

interface ChatTextViewProps {
  message: ChatMessage;
  edit: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

const ChatTextView = ({ message, edit, onSaved, onCancel }: ChatTextViewProps) => {
  const [updateMessage] = usePutChatMessageMutation();

  const [currentText, setCurrentText] = useState(message.text);

  useEffect(() => {
    setCurrentText(message.text);
  }, [message]);

  const handleSaveEditClick = async () => {
    await updateMessage({
      chatId: message.chatId,
      chatMessageId: message.id,
      text: currentText
    })
      .unwrap()
      .catch(() => {
        console.error(`Failed to update chat message`);
      })
      .then(() => {
        onSaved();
      });
  };

  const handleCancelEditClick = () => {
    setCurrentText(message.text);
    onCancel();
  };

  return (
    <Box mx="auto">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {edit ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <TextField
              size="small"
              autoFocus
              multiline
              rows={4}
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
              variant="outlined"
              sx={{ width: '100%' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, pt: 1 }}>
              <Button
                size="small"
                variant="contained"
                type="button"
                onClick={handleSaveEditClick}
                disabled={currentText == message.text}
              >
                Save
              </Button>
              <Button size="small" variant="outlined" type="button" onClick={handleCancelEditClick}>
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: message.text }} />
        )}
      </Box>
    </Box>
  );
};

export default ChatTextView;
