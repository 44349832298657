import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { createContext, ReactNode, useMemo, useState } from 'react';
import { getThemeOptions, type ThemeMode } from './theme';

interface ColorContextType {
  toggleColorMode: () => void;
}
interface ColorModeProviderProps {
  children: ReactNode;
}

const ColorModeContext = createContext<ColorContextType>({
  toggleColorMode: () => undefined
});

const setThemeInStorage = (theme: string) => {
  localStorage.setItem('theme', theme);
};

const getInitialThemeMode = (): ThemeMode => {
  const inStorage = localStorage.getItem('theme');

  if (inStorage) {
    return inStorage as ThemeMode;
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferred = prefersDarkMode ? 'dark' : 'light';
  setThemeInStorage(preferred);
  return preferred;
};

function ColorModeProvider({ children }: ColorModeProviderProps) {
  const [mode, setMode] = useState<ThemeMode>(getInitialThemeMode());
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          setThemeInStorage(newMode);
          return newMode;
        });
      }
    }),
    []
  );

  const theme = useMemo(() => createTheme(getThemeOptions(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function useColorContext() {
  const context = React.useContext(ColorModeContext);
  if (context === undefined) {
    throw new Error('toggleColorMode must be used within a ColorContextProvider');
  }
  return context;
}

export { ColorModeProvider, useColorContext };
