enum FieldType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN'
}

enum FormStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED'
}

type FormId = string;

interface Field {
  name: string;
  value: string | null;
  type: FieldType;
  description: string;
  required: boolean;
}

enum FormOptionNames {
  FORM_SUBMIT_LABEL = 'formSubmitLabel',
  FORM_REJECT_LABEL = 'formRejectLabel'
}

interface FormOption {
  name: string;
  value: string;
}

interface Form {
  id: FormId;
  title: string;
  description: string;
  fields: Field[];
  options: FormOption[];
  status: FormStatus;
}

export { FieldType, FormStatus, FormOption, FormOptionNames, FormId, Field, Form };
