import Drawer from '@mui/material/Drawer';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { MenuList } from './MenuList';
import Logo from '../../components/Logo';

interface SideBarProps {
  open: boolean;
  onCloseClick: () => void;
}

const CloseButton = ({ onClick }) => {
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        mr: 2,
        mt: 2
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const SideBar = ({ open, onCloseClick }: SideBarProps) => {
  const children = () => {
    return <MenuList onClick={() => onCloseClick()} />;
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '300px' }
      }}
    >
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => onCloseClick()}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: '100%',
          bgcolor: 'background.default',
          '& .MuiDrawer-paper': {
            width: '100%'
          }
        }}
      >
        <Box
          sx={{
            top: 0,
            right: 0,
            m: 2,
            mb: 5
          }}
        >
          <Logo />
          <CloseButton onClick={() => onCloseClick()} />
        </Box>
        {children()}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          bgcolor: 'background.default',
          '& .MuiDrawer-paper': {
            bgcolor: 'background.default',
            position: 'inherit',
            border: 'none',
            overflow: 'clip',
            pt: 5
          }
        }}
      >
        {children()}
      </Drawer>
    </Box>
  );
};

export default SideBar;
