import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useTheme } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface HelpTextProps {
  text: string;
}

export default function HelpText({ text }: HelpTextProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        backgroundColor: `${theme.palette.info.main}30`,
        color: theme.palette.text.primary,
        p: 1,
        borderRadius: theme.shape.borderRadius,
        alignItems: 'center'
      }}
    >
      <HelpIcon color="info" />
      <Typography variant="subtitle1" color="info.main">
        {text}
      </Typography>
    </Box>
  );
}
