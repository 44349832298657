import React from 'react';
import { IconButton, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TaskAndProgress, WorkflowInstance } from '../../types/workflow';
import { TaskResultView } from './TaskResultView';
import { TaskId } from '../../types/task';
import { TaskStatusChip } from '../../components/TaskStatusChip';
import SectionTable from '../../components/SectionTable';

interface WorkflowProgressProps {
  tasks: TaskAndProgress[];
  workflow: WorkflowInstance;
  isTaskSelected: (taskId: TaskId) => boolean;
  onTaskClick: (taskId: TaskId) => void;
}

export const WorkflowProgress = ({ workflow, tasks, isTaskSelected, onTaskClick }: WorkflowProgressProps) => {
  const handleRowClick = (taskId: string) => {
    onTaskClick(taskId);
  };

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Name</TableCell>
          <TableCell>Records</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((taskStat, index) => (
          <React.Fragment key={index}>
            <TableRow>
              <TableCell>
                <IconButton size="small" onClick={() => handleRowClick(taskStat.taskId)}>
                  {isTaskSelected(taskStat.taskId) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>{taskStat.name}</TableCell>
              <TableCell>{taskStat.completed}</TableCell>
              <TableCell align="right">
                <TaskStatusChip status={taskStat.status} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <TaskResultView workflow={workflow} task={taskStat} open={isTaskSelected(taskStat.taskId)} />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </SectionTable>
  );
};
