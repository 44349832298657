import React, { useEffect, useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MapComponent from './map';
import DocumentsOverview from './DocumentsOverview';
import { useLazyGetExtractionsByDocumentIdQuery, useLazySearchDocumentsQuery } from '../../state/api';
import { ContractingPartyMarker, convert } from './map/model';
import SectionHeader from '../../components/SectionHeader';
import UploadArea from './UploadArea';
import { Alert } from '@mui/material';
import SectionBody from '../../components/SectionBody';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  DocumentId,
  isDateAnnotation,
  isStringAnnotation,
  StandardLabel,
  TextExtraction
} from '../../types/annotation';
import { DocumentDetails, DocumentSearchQuery, SortOrder } from '../../types/document';
import { MapPoint } from '../../components/MapView';
import DocumentsKpiView from './DocumentsKpiView';

export interface DocumentWithExtractions {
  document: DocumentDetails;
  extractions: TextExtraction[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ pt: 1, display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

const getInitialSearch = (): string => {
  return localStorage.getItem('searchFilter') || '';
};

export default function Documents() {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState(getInitialSearch());
  const [sortedBy] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [contractsOnly, setContractsOnly] = React.useState<boolean>(true);
  const [documents, setDocuments] = useState<DocumentWithExtractions[]>([]);

  const [searchDocuments, result] = useLazySearchDocumentsQuery();
  const [triggerGetExtractionsByDocumentId] = useLazyGetExtractionsByDocumentIdQuery();

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('searchFilter', event.target.value);
    setSearch(event.target.value);
  };
  const handleChangeSortOrder = () => {
    setSortOrder((prev) => {
      if (prev === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  useEffect(() => {
    const q: DocumentSearchQuery = {
      query: '', // use 'search' when not filtering in ui anymore
      onlyContracts: contractsOnly,
      deleted: false,
      sortedBy: 'createdAt',
      sortOrder: sortOrder
    };

    searchDocuments(q);
  }, [contractsOnly, sortOrder]);

  useEffect(() => {
    const data = Array.from(result.data ?? []);

    setDocuments((docs) => {
      const updated = [];

      data.forEach((n) => {
        const existing = docs.find((e) => e.document.id == n.id);
        if (existing) {
          updated.push({ document: n, extractions: existing.extractions });
        } else {
          updated.push({ document: n, extractions: [] });
        }
      });

      return updated;
    });

    for (const doc of data) {
      triggerGetExtractionsByDocumentId(doc.id).then((result) => {
        const extractions = result.data;
        setDocuments((docs) =>
          docs.map((d) => {
            if (d.document.id === doc.id) {
              return { document: d.document, extractions: extractions };
            } else {
              return d;
            }
          })
        );
      });
    }
  }, [result]);

  const withExtractionsThatContains = (extractions: TextExtraction[], filter: string): boolean => {
    if (extractions.length > 0) {
      // Only Extractions of StandardLabel 'supplier' are considered (for now)
      const supplier = normalisedExtractionValue(extractions.find((e) => e.labelName === StandardLabel.Supplier));
      return normalizeSupplier(supplier).toLowerCase().includes(filter);
    }
    return false;
  };

  const filteredDocuments: DocumentWithExtractions[] = useMemo(() => {
    if (search.length > 0) {
      return documents.filter(
        (doc) =>
          doc.document.name.toLowerCase().includes(search.toLowerCase()) ||
          withExtractionsThatContains(doc.extractions, search.toLowerCase())
      );
    } else {
      return documents;
    }
  }, [documents, search]);

  const handleChangeInContractsOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractsOnly(event.target.checked);
  };

  const mapPoints: MapPoint<ContractingPartyMarker>[] = useMemo(() => {
    if (filteredDocuments.length > 0) {
      const partyPoints = filteredDocuments.map((doc) => {
        return convert(doc.document)
          .filter((partyMarker) => partyMarker.location)
          .map((partyMarker) => {
            return {
              id: partyMarker.extractionId,
              lat: partyMarker.location.lat,
              lng: partyMarker.location.lng,
              data: partyMarker
            };
          });
      });
      return partyPoints.flat();
    } else {
      return [];
    }
  }, [filteredDocuments]);

  function normalizeSupplier(name: string): string {
    if (name) {
      let result = name.replace(/\s+/g, ' ');
      result = result.replace(/[.,]/g, '');
      result = result.replace(/[()]/g, '');
      return result.toUpperCase();
    } else {
      return '';
    }
  }

  function normalisedExtractionValue(extraction: TextExtraction): string {
    if (extraction && isStringAnnotation(extraction)) {
      return extraction.value.string;
    } else if (extraction && isDateAnnotation(extraction)) {
      return extraction.value.date ? extraction.value.date.toString() : '';
    } else {
      return '';
    }
  }

  const extractionValue = (document: DocumentWithExtractions, label: StandardLabel) => {
    if (document.extractions.length > 0) {
      const docExtractions = document.extractions;
      if (docExtractions) {
        const extraction = docExtractions.find((extraction) => extraction.labelName === label);
        if (extraction) {
          return normalisedExtractionValue(extraction);
        }
      } else {
        return '';
      }
    }
  };

  const getExtractionValue = (documentId: DocumentId, label: StandardLabel): string => {
    const document = filteredDocuments.find((d) => d.document.id === documentId);

    const value = extractionValue(document, label);

    if (label === StandardLabel.Supplier) {
      return normalizeSupplier(value);
    }

    return value;
  };

  return (
    <>
      <SectionHeader title="Documents">
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          size="small"
          disabled={documents.length === 0}
          sx={{
            width: '100%',
            maxWidth: '30rem'
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={contractsOnly} onChange={handleChangeInContractsOnly} />}
            label="contracts only"
            disabled={documents.length === 0}
          />
        </FormGroup>
        <UploadArea onProcessStarted={() => {}} />
      </SectionHeader>
      <SectionBody>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Overview" />
            <Tab label="Map View" disabled={documents.length === 0} />
            <Tab label="KPI View" disabled={documents.length === 0} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {documents.length === 0 ? (
            <Alert severity="info">No documents available</Alert>
          ) : (
            <DocumentsOverview
              documents={filteredDocuments}
              isLoading={result && result.isLoading}
              getExtractionValue={getExtractionValue}
              sortedBy={sortedBy}
              sortOrder={sortOrder}
              onChangeSortOrder={handleChangeSortOrder}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapComponent mapPoints={mapPoints} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentsKpiView
            documents={filteredDocuments}
            isLoading={result && result.isLoading}
            getExtractionValue={getExtractionValue}
          />
        </TabPanel>
      </SectionBody>
    </>
  );
}
