import React from 'react';
import Box from '@mui/material/Box';
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useAdminActivateUserMutation, useAdminUsersToActivateQuery } from '../../state/api';

const AdminView = () => {
  const { data: usersToActivate = [] } = useAdminUsersToActivateQuery();
  const [activateUser] = useAdminActivateUserMutation();

  const handleSave = async (userId: string) => {
    try {
      await activateUser(userId).unwrap();
    } catch (error) {
      console.error('Failed to activate user', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Typography variant="h6">Users to activate</Typography>
      <List dense sx={{ maxWidth: '40rem', bgcolor: 'background.paper' }}>
        {usersToActivate.length === 0 && (
          <ListItem key="none">
            <ListItemText primary={'No users to activate'} />
          </ListItem>
        )}
        {usersToActivate.map((user) => (
          <ListItem
            key={user.id}
            secondaryAction={
              <Button variant="outlined" onClick={() => handleSave(user.id)}>
                Activate
              </Button>
            }
          >
            <ListItemText secondary={`${user.username}`} primary={`${user.firstName} ${user.lastName}`} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AdminView;
