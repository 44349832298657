import { useMemo } from 'react';
import { useMeQuery } from '../state/api';
import { ApiError, User } from '../state/model';

export interface AuthContext {
  isLoggedIn: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  user?: User;
}

export const useAuth = (): AuthContext => {
  const { data: user, isLoading, isSuccess, error } = useMeQuery();

  const typedError = error as ApiError | undefined;

  const isError = typedError && typedError.status === 'FETCH_ERROR';

  return useMemo<AuthContext>(
    () => ({
      isLoggedIn: user && true,
      isLoading: isLoading,
      isSuccess: isSuccess,
      isError: isError,
      user: user
    }),
    [user, isLoading, isSuccess, error]
  );
};
