import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateWorkflowMutation, useGetMirrorsQuery } from '../../state/api';
import SectionHeader from '../../components/SectionHeader';

export const WorkflowCreate = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [flowName, setFlowName] = useState<string>('');
  const [selectedMirror, setSelectedMirror] = useState<string>(null);
  const { data: mirrors = [] } = useGetMirrorsQuery();
  const [createWorkflow, { isLoading }] = useCreateWorkflowMutation();
  const mirrorIdParam = searchParams.get('mirrorId');

  useEffect(() => {
    const mirror = mirrors.find((m) => m.id === mirrorIdParam);
    if (mirror) {
      setSelectedMirror(mirrorIdParam);
    }
  }, [mirrors, mirrorIdParam]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      const workflow = await createWorkflow({
        mirrorId: selectedMirror,
        name: flowName
      }).unwrap();

      if (workflow) {
        navigate(`/workflows/${workflow.workflowInstanceId}`);
      }
    } catch (error) {
      console.error('creating new cup failed:', error);
    }
  };

  const handleCancel = () => {
    navigate('/tasks');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedMirror(value);
  };

  return (
    <>
      <SectionHeader title="Workflows" />

      <form onSubmit={handleOnSubmit}>
        <Box sx={{ minWidth: 500, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body2" component="div"></Typography>
          <TextField
            label="Workflow Name"
            variant="outlined"
            value={flowName}
            onChange={(e) => setFlowName(e.target.value)}
            required
            autoFocus
            disabled={isLoading}
          />
          <FormControl>
            <InputLabel id="mirrorLabel">Select Mirror</InputLabel>
            <Select
              labelId="mirrorLabel"
              name="mirrorId"
              value={selectedMirror}
              label="Select Mirror"
              onChange={handleChange}
              disabled={isLoading}
            >
              {mirrors &&
                mirrors.length > 0 &&
                mirrors.map((mirror, index) => (
                  <MenuItem value={mirror.id} key={index}>
                    {mirror.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Button variant="contained" type="submit" disabled={isLoading || !selectedMirror}>
              Create
            </Button>
            <Button variant="outlined" type="button" onClick={() => handleCancel()} disabled={isLoading}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};
