import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Card, IconButton, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Cup } from '../../types/cup';
import { CupStandardLabelForm } from './CupStandardLabelForm';
import { Label } from '../../types/label';
import AddIcon from '@mui/icons-material/Add';
import SectionTable from '../../components/SectionTable';

export interface CupStandardLabelEditorProps {
  cup: Cup;
  labels: Label[];
}

export const CupStandardLabelEditor = ({ cup, labels }: CupStandardLabelEditorProps) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const handleCloseForm = () => setIsFormOpen(false);
  const [selectedLabels, setSelectedLabels] = useState<Label[]>();

  useEffect(() => {
    if (cup && cup.labels && cup.labels.length > 0) {
      const filteredLabels = labels.filter((label) => cup.labels && cup.labels.indexOf(label.labelId) != -1);
      setSelectedLabels(filteredLabels);
    }
  }, [cup, labels]);

  return (
    <Box>
      {!isFormOpen && (
        <SectionTable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">
                <IconButton color="primary" edge="start" onClick={() => setIsFormOpen(true)} size="medium">
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedLabels &&
              selectedLabels.map((label) => (
                <TableRow key={label.labelId} hover onClick={() => console.log('22')}>
                  <TableCell>{label.name}</TableCell>
                  <TableCell>{label.description.description}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </SectionTable>
      )}
      {isFormOpen && (
        <Card sx={{ p: 2 }}>
          <CupStandardLabelForm cup={cup} labels={labels} onCompleted={handleCloseForm} onCancel={handleCloseForm} />
        </Card>
      )}
    </Box>
  );
};
