import Avatar from '@mui/material/Avatar';
import React from 'react';
import { User } from '../state/model';

interface UserAvatarProps {
  user: User;
  small?: boolean;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  return (
    <Avatar
      variant="circular"
      sx={{
        width: 32,
        height: 32,
        backgroundColor: 'primary.main'
      }}
    >
      {user.username.charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
