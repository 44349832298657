import { Box, useTheme } from '@mui/material';
import React from 'react';
import logoIcon from '../assets/digital-mirror-symbol-blue_64.png';
import Picture from './Picture';

interface SystemAvatarProps {
  small?: boolean;
}

const SystemAvatar = ({ small = false }: SystemAvatarProps) => {
  const theme = useTheme();
  const size = small ? theme.spacing(2) : theme.spacing(3.2);

  return (
    <Box
      sx={{
        width: size,
        height: size
      }}
    >
      <Picture src={logoIcon} alt="Mirage" />
    </Box>
  );
};

export default SystemAvatar;
