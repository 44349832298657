import React from 'react';
import { Box, Step, StepContent, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import SectionHeader from '../../components/SectionHeader';
import { useGetMirrorsQuery, useGetWorkflowsQuery } from '../../state/api';
import ProgressCircle from '../../components/ProgressCircle';
import mirrorImage from '../../assets/woman-in-front-of-mirror-with-cogwheels.png';
import Picture from '../../components/Picture';
import Link from '../../components/Link';

export const Dashboard = () => {
  const { data: mirrors = [], isLoading: isMirrorsLoading } = useGetMirrorsQuery();
  const { data: workflows = [], isLoading: isWorkflowsLoading } = useGetWorkflowsQuery();
  const theme = useTheme();

  const tutorial = () => {
    const activeStep = (mirrors.length && 1) + (workflows.length && 1);

    const secondStep = () => {
      const color = mirrors.length === 0 ? theme.palette.text.disabled : theme.palette.text.primary;

      return (
        <Step expanded>
          <StepLabel>Create a workflow</StepLabel>
          <StepContent>
            <Typography variant="body1" color={color} gutterBottom>
              After you have crated a mirror you can then run the mirrors as many times as you wish on different sets of
              input.
            </Typography>

            {mirrors.length > 0 && (
              <Typography variant="body1" color={color} gutterBottom>
                Go to Workflows and run your mirror.
              </Typography>
            )}
          </StepContent>
        </Step>
      );
    };

    return (
      <>
        <Typography variant="body1" gutterBottom>
          Create <strong>mirrors</strong> of tasks and then run those as <strong>workflows</strong>.
        </Typography>

        <Stepper orientation="vertical" activeStep={activeStep}>
          <Step expanded>
            <StepLabel>Create a mirror</StepLabel>
            <StepContent>
              <Typography variant="body1">
                Navigate to <Link to="/mirrors">Mirrors</Link> to get started.
              </Typography>
            </StepContent>
          </Step>
          {secondStep()}
        </Stepper>
      </>
    );
  };

  const dashboard = () => {
    return (
      <Typography variant="body1">
        You have created {mirrors.length} mirrors and {workflows.length} workflow{workflows.length > 1 && 's'}. Great
        job!
      </Typography>
    );
  };

  const content = () => {
    if (isMirrorsLoading || isWorkflowsLoading) {
      return <ProgressCircle />;
    }

    const showTutorial = mirrors.length === 0 || workflows.length === 0;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Picture
          src={mirrorImage}
          alt="Woman in front of mirror with cogwheels"
          sx={{
            width: '100%',
            maxWidth: { xs: '65%', sm: '15rem' }
          }}
        />
        <SectionHeader title="Welcome to Digital Mirror" centered />

        {showTutorial ? tutorial() : dashboard()}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          p: 4,
          mt: { xs: 0, sm: 5 },
          width: '100%',
          maxWidth: '38rem'
        }}
      >
        {content()}
      </Box>
    </Box>
  );
};
