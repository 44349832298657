import React from 'react';
import ChatConsole from './ChatConsole';
import { Box } from '@mui/material';
import ChatMain from './ChatMain';

interface ChatProps {
  chatId: string;
  onMessage?: (message: string) => void;
}

const Chat = ({ chatId }: ChatProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto min-content',
        overflow: 'hidden'
      }}
    >
      <ChatMain chatId={chatId} />
      <ChatConsole chatId={chatId} disabled={false} />
    </Box>
  );
};

export default Chat;
