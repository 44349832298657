import { Mirror } from '../../../types/mirror';
import { TaskDefinition, TaskDefinitionInputParameter } from '../../../types/task';
import { useGetJoinTaskQuery, useGetWorkflowDefinitionByIdQuery, useUpdateJoinTaskMutation } from '../../../state/api';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { Checkbox, FormControlLabel, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { OutputParametersEditor } from './OutputParametersEditor';
import { SectionPanel } from '../../../components/SectionPanel';
import ProgressCircle from '../../../components/ProgressCircle';
import SectionTable from '../../../components/SectionTable';

interface MirrorJoinTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorJoinTaskEditor = ({ mirror, task, onClose }: MirrorJoinTaskEditorProps) => {
  const { data: workflow } = useGetWorkflowDefinitionByIdQuery(mirror.workflowId);

  const { data: join } = useGetJoinTaskQuery({
    workflowId: mirror.workflowId,
    taskId: task.taskId
  });

  const [updateJoinTask] = useUpdateJoinTaskMutation();
  const [saving, setSaving] = useState(false);

  const saveJoinKeys = async (keys: string[]) => {
    try {
      setSaving(true);
      await updateJoinTask({ workflowId: mirror.workflowId, taskId: task.taskId, keys: keys }).unwrap();
    } catch (error) {
      console.error('Failed to save join keys', error);
    } finally {
      setSaving(false);
    }
  };

  const isChecked = (key: string): boolean => {
    return join && join.keys.includes(key);
  };

  const handleKeyUpdate = async (event: React.ChangeEvent<HTMLInputElement>, labelId: string) => {
    if (event.target.checked) {
      const update = [...join.keys, labelId];
      await saveJoinKeys(update);
    } else {
      const update = join.keys.filter((k) => k != labelId);
      await saveJoinKeys(update);
    }
  };

  const parentTasks = useMemo(() => {
    return workflow.tasks.filter((t) => t.children.map((t) => t.taskId).includes(task.taskId));
  }, [workflow]);

  const numSourcePaths = useMemo(() => {
    return parentTasks.filter(
      (parentTask) =>
        !parentTasks.some((otherParentTask) =>
          otherParentTask.children.map((child) => child.taskId).includes(parentTask.taskId)
        )
    ).length;
  }, [parentTasks]);

  const hasMappedOutput = (parent: TaskDefinition, inputs: TaskDefinitionInputParameter[]): boolean => {
    return parent.output.some((o) => inputs.some((ii) => o.parameterId === ii.mappedBy));
  };

  const numberOfMappedOutputs = (inputs: TaskDefinitionInputParameter[]): number => {
    if (!parentTasks) {
      return 0;
    }

    return parentTasks.map((parent) => (hasMappedOutput(parent, inputs) ? 1 : 0)).reduce((prev, cur) => prev + cur, 0);
  };

  const isCandidate = (inputs: TaskDefinitionInputParameter[]): boolean => {
    return numSourcePaths >= inputs.length && numberOfMappedOutputs(inputs) === numSourcePaths;
  };

  const mappedByLabel = Object.groupBy(task.input, ({ labelId }) => labelId);
  //
  // const sortByMapped = (a: LabelInputs, b: LabelInputs): number => {
  //   console.log('A: ' + Object.values(a[1]));
  //
  //   const first = numberOfMappedOutputs(a['inputs']);
  //   const second = numberOfMappedOutputs(b['inputs']);
  //
  //   return first - second;
  // };

  return (
    <MirrorTaskEditorPanel mirror={mirror} task={task} onClose={onClose}>
      <Typography variant="caption">Choose labels to join your data on</Typography>

      <SectionPanel title="Join keys">
        <SectionTable>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              {parentTasks && parentTasks.map((parent) => <TableCell key={parent.taskId}>{parent.name}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(mappedByLabel)
              // .sort(sortByMapped)
              .map(([labelId, inputs]) => (
                <TableRow key={labelId}>
                  <TableCell>
                    {join && join.keys ? (
                      <FormControlLabel
                        disabled={saving || !isCandidate(inputs)}
                        control={
                          <Checkbox checked={isChecked(labelId)} onChange={(e) => handleKeyUpdate(e, labelId)} />
                        }
                        label={inputs[0].description.title}
                      />
                    ) : (
                      <ProgressCircle />
                    )}
                  </TableCell>
                  {parentTasks &&
                    parentTasks.map((parent) => {
                      return (
                        <TableCell key={parent.taskId}>
                          {hasMappedOutput(parent, inputs) ? <CheckIcon color={'primary'} /> : ''}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
          </TableBody>
        </SectionTable>
      </SectionPanel>

      <SectionPanel title="Output">
        <OutputParametersEditor workflowId={mirror.workflowId} task={task} readOnly={true} />
      </SectionPanel>
    </MirrorTaskEditorPanel>
  );
};
