import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import SideBar from './Sidebar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ProgressCircle from '../../components/ProgressCircle';
import LandingPage from '../landingpage/LandingPage';
import TopBar from './TopBar';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../../hooks/useAuth';

export const ProtectedLayout = () => {
  const location = useLocation();
  const { user, isLoading, isSuccess, isError } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (isLoading && !isError) {
    return <ProgressCircle />;
  }

  if (isError) {
    return <div>Site is down</div>;
  }

  if (isSuccess && user) {
    if (!user.activated) {
      return <Navigate to="/activation" state={{ from: location.pathname }} replace />;
    }
  } else {
    return <LandingPage />;
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 0,
        m: 0,
        display: 'grid',
        gridTemplateRows: '70px auto'
      }}
    >
      <Box component={'header'}>
        <TopBar open={sidebarOpen} onOpenClick={() => setSidebarOpen(true)} />
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'grid' },
          gridTemplateColumns: { xs: 'none', sm: '180px auto' },
          pb: { xs: 0, sm: 1 },
          pr: { xs: 0, sm: 2 },
          overflowY: 'hidden'
        }}
      >
        <Box component={'aside'}>
          <SideBar open={sidebarOpen} onCloseClick={() => setSidebarOpen(false)} />
        </Box>

        <Paper
          component={'main'}
          elevation={isMobile ? 0 : 1}
          sx={{
            p: 2,
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <Outlet />
        </Paper>
      </Box>
    </Box>
  );
};
