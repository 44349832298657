import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePostCupMutation } from '../../state/api';
import { CupType } from '../../types/cup';

export const CupCreateForm = () => {
  const [cupName, setCupName] = useState('');
  const [cupType, setCupType] = useState(CupType.EXTRACTION);
  const navigate = useNavigate();
  const [postCup, { isLoading, isError }] = usePostCupMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const cup = await postCup({
        name: cupName,
        type: cupType
      }).unwrap();

      if (cup) {
        navigate(`/cups/${cup.id}`);
      }
    } catch (error) {
      console.error('creating new CUP failed:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCupType(CupType[value as CupType]);
  };

  const onCancel = () => {
    navigate('/cups');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          m: 2
        }}
      >
        <Typography variant={'h6'}>Create CUP</Typography>
        <TextField
          label="CUP Name"
          variant="outlined"
          value={cupName}
          onChange={(e) => setCupName(e.target.value)}
          required
          autoFocus
        />
        <FormControl variant="outlined">
          <InputLabel id="cup-type-label">Cup Type</InputLabel>
          <Select labelId="cup-type-label" name="cupType" value={cupType} onChange={handleChange} label="CUP Type">
            <MenuItem value={'EXTRACTION'}>Document</MenuItem>
            <MenuItem value={'TABULAR'}>Tablular Data</MenuItem>
          </Select>
        </FormControl>
        {isError && <Typography color="error">Error adding the Cup</Typography>}
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          <Button type="submit" variant="contained" color="primary" disabled={cupName.length == 0 || isLoading}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};
