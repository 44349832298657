import { Box, Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { TaskParameterDefinition } from '../../../types/task';
import SelectableLabel from '../../../components/SelectableLabel';
import InputParameterCreateForm from './InputParameterCreateForm';

interface InputParameterCreateFormProps {
  onAddParameters: (selected: TaskParameterDefinition[]) => void;
  availableOutputs: TaskParameterDefinition[];
  onCancel?: () => void;
  onCreateParameter: (name: string) => void;
}

const InputParameterAddForm = ({
  onAddParameters,
  availableOutputs,
  onCancel,
  onCreateParameter
}: InputParameterCreateFormProps) => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [selectedParameters, setSelectedParameters] = useState<TaskParameterDefinition[]>([]);

  const isSelected = (column: TaskParameterDefinition): boolean => {
    return selectedParameters.findIndex((c) => c.parameterId === column.parameterId) >= 0;
  };

  const handleSelected = (column: TaskParameterDefinition) => {
    if (isSelected(column)) {
      setSelectedParameters((prev) => prev.filter((p) => p.parameterId !== column.parameterId));
    } else {
      setSelectedParameters((prev) => [...prev, column]);
    }
  };

  const handleCancel = () => {
    setSelectedParameters([]);
    if (onCancel) {
      onCancel();
    }
  };

  const handleCreateParameter = async (name: string) => {
    onCreateParameter(name);
    setShowCreate(false);
  };

  const handleAddParameters = async () => {
    onAddParameters(selectedParameters);
    setSelectedParameters([]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          flexWrap: 'wrap'
        }}
      >
        {availableOutputs.map((output, index) => (
          <SelectableLabel
            key={index}
            onClick={() => handleSelected(output)}
            name={output.description.title}
            selected={isSelected(output)}
          />
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        {showCreate ? (
          <InputParameterCreateForm onCreateParameter={handleCreateParameter} onCancel={() => setShowCreate(false)} />
        ) : (
          <>
            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disabled={selectedParameters.length === 0}
                onClick={() => handleAddParameters()}
              >
                Add Selected
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={selectedParameters.length === 0}
                onClick={handleCancel}
              >
                Deselect All
              </Button>
            </ButtonGroup>
            <Button variant="outlined" size="small" color="secondary" onClick={() => setShowCreate(true)}>
              Create
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default InputParameterAddForm;
