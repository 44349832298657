import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Autocomplete
} from '@mui/material';
import { countryData, Country } from '../../state/country';
import { useMeQuery, useUpdateUserMutation } from '../../state/api';
import { UpdateUserRequest } from '../../state/model';

interface SettingsDialogProps {
  open: boolean;
  handleClose: () => void;
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, handleClose }) => {
  const { data: user } = useMeQuery();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({ settings: '', yearOfBirth: '' });

  const [formValues, setFormValues] = useState<UpdateUserRequest>({
    yearOfBirth: 0,
    country: ''
  });

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const validationYear = new Date().getFullYear() - 5; // World primary school starting age: https://data.worldbank.org/indicator/SE.PRM.AGES

    const newErrors = { ...errors };
    const regEx = /^\d{4}$/; // 4-digit number
    if (!regEx.test(value) || Number(value) > validationYear || Number(value) < 1900) {
      newErrors.yearOfBirth = 'Invalid Year of Birth';
    } else {
      newErrors.yearOfBirth = '';
    }

    setErrors(newErrors);
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    updateUser(formValues)
      .unwrap()
      .then(() => handleClose())
      .catch((error) => {
        const newErrors = { ...errors };

        if (error.status === 401) {
          newErrors.settings = 'Unauthorized';
        } else if (error.data) {
          newErrors.settings = error.data.message;
        } else {
          newErrors.settings = 'Unknown error';
        }

        setErrors(newErrors);
        setShowError(true);
      });
  };

  useEffect(() => {
    if (user) {
      setFormValues({
        yearOfBirth: user.yearOfBirth,
        country: user.country
      });
    }
  }, [user]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box marginBottom={2} marginTop={1}>
          <TextField
            id="yearOfBirth"
            name="yearOfBirth"
            label="Year of birth"
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={isLoading}
            value={formValues.yearOfBirth}
            error={!!errors.yearOfBirth}
            helperText={errors.yearOfBirth}
            onChange={handleTextFieldChange}
          />
        </Box>

        <Autocomplete
          id="country"
          options={countryData}
          getOptionLabel={(option: Country) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          value={countryData.find((country) => country.name === formValues.country) || null}
          onChange={(_event: SyntheticEvent, newValue: Country | null) => {
            setFormValues({
              ...formValues,
              country: newValue ? newValue.name : formValues.country
            });
          }}
          disabled={isLoading}
          renderInput={(params) => (
            <TextField {...params} label="Country" margin="normal" variant="outlined" fullWidth />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={Object.entries(errors).some(([key, value]) => key !== 'settings' && value !== '')}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
      <Snackbar open={showError} autoHideDuration={6000}>
        <Alert severity="warning" sx={{ width: '100%' }}>
          {errors.settings}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default SettingsDialog;
