import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import {
  AggregateFunction,
  AggregateFunctionType,
  TaskAggregateConfig,
  TaskDefinition,
  TaskParameter
} from '../../../types/task';
import { Mirror } from '../../../types/mirror';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export interface DeleteFunctionEvent {
  taskId: string;
  labelId: string;
}

export interface AddFunctionEvent {
  taskId: string;
  aggregateFunction: AggregateFunction;
}

interface MirrorAggregateTaskFunctionEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  config: TaskAggregateConfig;
  onDelete?: (event: DeleteFunctionEvent) => void;
  onAdd?: (event: AddFunctionEvent) => void;
  onClose: () => void;
}

export function MirrorAggregateTaskFunctionEditor({
  mirror,
  task,
  config,
  onDelete,
  onAdd
}: MirrorAggregateTaskFunctionEditorProps) {
  const emptyAggregateFunction: AggregateFunction = {
    labelId: null,
    type: null,
    options: []
  };

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [availableKeys, setAvailableKeys] = useState<TaskParameter[]>([]);
  const [aggregateFunction, setAggregateFunction] = useState<AggregateFunction>(emptyAggregateFunction);
  const [separatorValue, setSeparatorValue] = useState<string>('');

  useEffect(() => {
    setAvailableKeys(task.input.filter((input) => !config.keys.some((key) => key == input.labelId)));
    const separatorOption = aggregateFunction.options.find((opt) => opt.key === 'separator');
    setSeparatorValue(separatorOption ? separatorOption.value : '');
  }, [mirror, config.keys, aggregateFunction.options]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onAdd) {
      onAdd({
        taskId: task.taskId,
        aggregateFunction: aggregateFunction
      });
    }
    setAggregateFunction(emptyAggregateFunction);
    setIsFormOpen(false);
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const key = availableKeys.find((output) => output.labelId == value);
    setAggregateFunction({
      ...aggregateFunction,
      labelId: key.labelId
    });
  };

  const stringToAggregateFunctionType = (value: string): AggregateFunctionType => {
    if (Object.values(AggregateFunctionType).includes(value as AggregateFunctionType)) {
      return value as AggregateFunctionType;
    }
    return null;
  };

  const handleChangeFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const functionType = stringToAggregateFunctionType(value);
    const defaultOptions = functionType == AggregateFunctionType.CONCAT ? [{ key: 'separator', value: '|||' }] : [];
    const options = functionType && functionType != aggregateFunction.type ? defaultOptions : aggregateFunction.options;
    setAggregateFunction({
      ...aggregateFunction,
      type: functionType,
      options: options
    });
  };

  const handleChangeSeparator = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const separatorConfig = aggregateFunction.options.find((option) => option.key == 'separator');

    setAggregateFunction({
      ...aggregateFunction,
      options: [{ ...separatorConfig, value: value }]
    });
  };

  const handleAddFunctionCancel = () => {
    setAggregateFunction(emptyAggregateFunction);
    setIsFormOpen(false);
  };

  const handleDeleteFunction = async (event: DeleteFunctionEvent) => {
    if (onDelete) {
      onDelete(event);
    }
  };

  const getNameByLabelid = (labelId: string): string => {
    return task.input.find((param) => param.labelId == labelId).description.title;
  };

  const getFunctionTypeName = (type: AggregateFunctionType): string => {
    switch (type) {
      case AggregateFunctionType.SUM:
        return 'Sum';
      case AggregateFunctionType.CONCAT:
        return 'Concatenation';
      default:
        return '';
    }
  };

  return (
    <Box>
      {isFormOpen && (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              m: 2
            }}
          >
            <Typography variant={'h6'}>Add Aggregate Function</Typography>
            <FormControl variant="outlined">
              <InputLabel id="functionLabelLabel">Label</InputLabel>
              <Select
                labelId="functionLabel"
                name="label"
                value={aggregateFunction.labelId ? aggregateFunction.labelId : ''}
                label="Label"
                onChange={handleChangeLabel}
              >
                {availableKeys.map((output, index) => (
                  <MenuItem key={index} value={output.labelId}>
                    {output.name}
                  </MenuItem>
                ))}
                ;
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel id="functionLabel">Function</InputLabel>
              <Select
                labelId="functionLabel"
                name="function"
                value={aggregateFunction.type ? aggregateFunction.type : ''}
                label="Function"
                onChange={handleChangeFunction}
              >
                <MenuItem value={AggregateFunctionType.SUM}>Sum</MenuItem>
                <MenuItem value={AggregateFunctionType.CONCAT}>Concatenate</MenuItem>
              </Select>
            </FormControl>
            {aggregateFunction.type && aggregateFunction.type == AggregateFunctionType.CONCAT && (
              <FormControl variant="outlined">
                <TextField
                  label="Value Separator"
                  variant="outlined"
                  value={separatorValue}
                  onChange={handleChangeSeparator}
                  required
                  autoFocus
                />
              </FormControl>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              <Button type="submit" variant="contained" color="primary" disabled={!aggregateFunction.labelId}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleAddFunctionCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      )}
      {!isFormOpen && (
        <TableContainer sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow component="th" scope="row">
                <TableCell>Label</TableCell>
                <TableCell>Function</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" edge="start" onClick={() => setIsFormOpen(true)} size="medium">
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {config.functions.map((fun) => (
                <TableRow
                  key={fun.labelId}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover .deleteIcon': {
                      opacity: 1
                    }
                  }}
                >
                  <TableCell>{getNameByLabelid(fun.labelId)}</TableCell>
                  <TableCell>{getFunctionTypeName(fun.type)}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDeleteFunction({ taskId: task.taskId, labelId: fun.labelId })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
