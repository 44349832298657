import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';

interface InputParameterCreateFormProps {
  onCreateParameter: (name: string) => void;
  onCancel?: () => void;
}

const InputParameterCreateForm = ({ onCreateParameter, onCancel }: InputParameterCreateFormProps) => {
  const [name, setName] = useState<string>('');

  const handleCreateParameter = async () => {
    onCreateParameter(name);
    setName('');
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
      }}
    >
      <TextField label="Create Label" value={name} onChange={(e) => setName(e.target.value)} />
      <Button variant="contained" color="primary" disabled={name.length === 0} onClick={() => handleCreateParameter()}>
        Create
      </Button>
      <Button variant="outlined" color="primary" onClick={() => handleCancel()}>
        Cancel
      </Button>
    </Box>
  );
};

export default InputParameterCreateForm;
