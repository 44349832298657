import React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Mirror } from '../../types/mirror';
import { TaskDefinition } from '../../types/task';
import { usePutTaskNameMutation } from '../../state/api';
import EditableHeader from '../../components/EditableHeader';

interface MirrorTaskEditorHeaderProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorTaskEditorHeader = ({ mirror, task, onClose }: MirrorTaskEditorHeaderProps) => {
  const [putTaskName, { isLoading: isPutTaskNameLoading }] = usePutTaskNameMutation();
  const updateTaskName = async (newName: string) => {
    try {
      await putTaskName({
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        name: newName
      }).unwrap();
    } catch (error) {
      console.error('Failed to change task name', error);
    }
  };

  const breadcrumbs = () => {
    return (
      <Breadcrumbs separator=">">
        <Link
          component={Typography}
          variant="h5"
          underline="hover"
          color="inherit"
          onClick={onClose}
          sx={{ cursor: 'pointer' }}
        >
          {mirror.name}
        </Link>
        <EditableHeader variant="h5" title={task.name} onSave={updateTaskName} loading={isPutTaskNameLoading} />
      </Breadcrumbs>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {breadcrumbs()}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
