import React from 'react';
import Box from '@mui/material/Box';
import EditableHeader from './EditableHeader';

interface MainHeaderProps {
  title: string;
  onSaveName?: (name: string) => void;
  savingName?: boolean;
  children?: React.JSX.Element | React.JSX.Element[];
  centered?: boolean;
}

export default function SectionHeader({ title, children, onSaveName, savingName, centered = false }: MainHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        justifyContent: 'space-between',
        ...(centered && {
          justifyContent: 'center'
        })
      }}
    >
      <EditableHeader variant="h5" title={title} onSave={onSaveName} loading={savingName} />
      {children}
    </Box>
  );
}
