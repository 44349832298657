import React from 'react';
import { Mirror } from '../../../types/mirror';
import { LabelProviderType, TaskDefinition } from '../../../types/task';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { useGetLabelProviderReferenceQuery } from '../../../state/api';
import CupExtractionEditor from '../../cups/CupExtractionEditor';

interface MirrorLabelProviderTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorLabelProviderTaskEditor = ({ mirror, task, onClose }: MirrorLabelProviderTaskEditorProps) => {
  const { data: providerReference, isLoading } = useGetLabelProviderReferenceQuery({
    workflowId: mirror.workflowId,
    taskId: task.taskId
  });

  return (
    <MirrorTaskEditorPanel mirror={mirror} task={task} onClose={onClose}>
      {!isLoading && providerReference && providerReference.providerType == LabelProviderType.CUP && (
        <CupExtractionEditor
          cupId={providerReference.providerId}
          taskReference={{
            mirrorId: mirror.id,
            workflowId: mirror.workflowId,
            taskId: task.taskId
          }}
        />
      )}
    </MirrorTaskEditorPanel>
  );
};
