import React from 'react';
import { TaskDefinition, TaskType } from '../../types/task';
import { Mirror } from '../../types/mirror';
import { MirrorGenericTaskEditor } from './task/MirrorGenericTaskEditor';
import { MirrorExportTaskEditor } from './task/MirrorExportTaskEditor';
import { MirrorLabelProviderTaskEditor } from './task/MirrorLabelProviderTaskEditor';
import { MirrorLogicTaskEditor } from './task/MirrorLogicTaskEditor';
import { MirrorAggregateTaskEditor } from './task/MirrorAggregateTaskEditor';
import { MirrorJoinTaskEditor } from './task/MirrorJoinTaskEditor';
import { useMirrorContext } from './MirrorContextProvider';

interface MirrorTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorTaskEditor = ({ mirror, task, onClose }: MirrorTaskEditorProps) => {
  const { clearSelection } = useMirrorContext();

  const handleClose = () => {
    onClose();
    clearSelection();
  };

  switch (task.type) {
    case TaskType.LOGIC:
      return <MirrorLogicTaskEditor task={task} mirror={mirror} onClose={handleClose} />;
    case TaskType.EXPORT:
      return <MirrorExportTaskEditor task={task} mirror={mirror} onClose={handleClose} />;
    case TaskType.LABEL_PROVIDER:
      return <MirrorLabelProviderTaskEditor task={task} mirror={mirror} onClose={handleClose} />;
    case TaskType.AGGREGATE:
      return <MirrorAggregateTaskEditor task={task} mirror={mirror} onClose={handleClose} />;
    case TaskType.JOIN:
      return <MirrorJoinTaskEditor task={task} mirror={mirror} onClose={handleClose} />;

    default:
      return <MirrorGenericTaskEditor task={task} mirror={mirror} onClose={handleClose} />;
  }
};
