import React, { useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { usePutUploadFilesMutation } from '../../state/api';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { UploadReference } from '../../types/upload';

interface UploadAreaProps {
  reference?: UploadReference;
  onProcessStarted: (processId: string) => void;
  onDropped?: () => void;
  disabled?: boolean;
}

const UploadArea = ({ reference, onProcessStarted, onDropped, disabled = false }: UploadAreaProps) => {
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [uploadFiles, { isLoading }] = usePutUploadFilesMutation();

  const onDrop = async (acceptedFiles: File[]) => {
    if (onDropped) {
      onDropped();
    }
    const formData = new FormData();
    if (reference) {
      formData.append('referenceId', reference.referenceId);
      formData.append('referenceType', reference.referenceType);
      formData.append('documentSetId', reference.documentSetId);
    }
    acceptedFiles.forEach((file) => {
      formData.append('file', file);
    });

    uploadFiles(formData)
      .unwrap()
      .then((data) => {
        onProcessStarted(data.processId);
        setStatusMessage(data.processId);
        setOpen(true);
      })
      .catch((error) => {
        setStatusMessage(error.message);
        setOpen(true);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          component={'label'}
          color="primary"
          startIcon={<AddIcon />}
          size="medium"
          disabled={isLoading || disabled}
        >
          Upload
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
        </Button>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        message={statusMessage}
        action={action}
      />
    </Box>
  );
};

export default UploadArea;
