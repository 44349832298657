import Logout from '@mui/icons-material/Logout';
import { Divider, FormControlLabel, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SettingsDialog from '../scenes/user/SettingsDialog';
import { useLogoutUserMutation, useMeQuery } from '../state/api';
import { logout } from '../state/authSlice';
import { useColorContext } from '../theme-context';
import ThemeSwitch from './ThemeSwitch';
import UserAvatar from './UserAvatar';

export default function ProfileMenu() {
  const theme = useTheme();
  const colorMode = useColorContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user } = useMeQuery();
  const [logoutUser] = useLogoutUserMutation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  // const handleClickSettingsOpen = () => {
  //   setSettingsOpen(true);
  // };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutUser()
      .unwrap()
      .then(() => {
        dispatch(logout());
        navigate('/', { replace: true });
        handleClose();
      });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {user && <UserAvatar user={user} />}
        </IconButton>
      </Box>
      <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose}>
        {/* <MenuItem onClick={handleClickSettingsOpen}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem>
          <FormControlLabel
            control={<ThemeSwitch checked={theme.palette.mode === 'dark'} onChange={colorMode.toggleColorMode} />}
            label="Switch theme"
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <SettingsDialog open={settingsOpen} handleClose={handleSettingsClose} />
    </React.Fragment>
  );
}
