import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import Logo from '../../components/Logo';
import { isMobile, MobileView } from 'react-device-detect';
import ProfileMenu from '../../components/ProfileMenu';

interface TopBarProps {
  open: boolean;
  onOpenClick: () => void;
  // onCloseClick: () => void;
}

const TopBar = ({ onOpenClick }: TopBarProps) => {
  return (
    <Box
      sx={{
        pr: 3,
        pl: 3,
        pt: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        <MobileView>
          <IconButton color="inherit" onClick={() => onOpenClick()} edge="start">
            <MenuIcon />
          </IconButton>
        </MobileView>
        <Logo showSymbol={!isMobile} />
      </Box>

      <ProfileMenu />
    </Box>
  );
};

export default TopBar;
