import { Form } from './form';

interface ChatRequest {
  message: string;
}

interface ChatCreatedResponse {
  chatId: string;
}

interface ChatCreateMessageRequest {
  chatId: string;
  type: ChatMessageType;
  dataReferenceId: string;
  text?: string;
  userId?: string;
}

interface ChatUpdateMessageRequest {
  chatId: string;
  chatMessageId: string;
  dataReferenceId?: string;
  text?: string;
}

interface ChatCreateTextRequest {
  chatId: string;
  text: string;
}

interface ChatMessageCreatedResponse {
  id: string;
  chatId: string;
}

interface ChatMessageUpdatedResponse {
  id: string;
  chatId: string;
}

interface ChatDeleteMessageRequest {
  chatId: string;
  chatMessageId: string;
}

enum ChatMessageType {
  TEXT = 'TEXT',
  FORM = 'FORM',
  UPLOAD = 'UPLOAD',
  ACTION = 'ACTION'
}

interface ChatText {
  id: string;
  text: string;
}

enum ChatUploadFileStatus {
  CREATED = 'CREATED',
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED'
}

interface ChatUploadFile {
  name: string;
  status: ChatUploadFileStatus;
}

enum ChatActionStatus {
  RUNNING,
  COMPLETED,
  FAILED
}

interface ChatUpload {
  processId: string;
  files: ChatUploadFile[];
  uiState?: {
    files: string[];
    status: ChatActionStatus;
    message: string;
  };
}

interface ChatUploadResponse {
  id: string;
}

interface ChatActionButton {
  name: string;
  url: string;
}

interface ChatAction {
  text: string;
  buttons: ChatActionButton[];
}

interface ChatMessage {
  id: string;
  chatId: string;
  type: ChatMessageType;
  text?: string;
  dataReferenceId: string;
  createdBy: string;
  createdAt: string;
}

interface ChatItem {
  message: ChatMessage;
  threadItems: ChatItem[];
  data?: Form | ChatUpload | ChatAction;
}

function isSystemItem(chatItem: ChatItem): boolean {
  return chatItem.message.createdBy == '00000000-0000-0000-0000-000000000000';
}

function isChatText(chatItem: ChatItem): boolean {
  return chatItem.message.type === ChatMessageType.TEXT;
}

function isForm(chatItem: ChatItem): boolean {
  return chatItem.message.type === ChatMessageType.FORM;
}

function isUploadFile(chatItem: ChatItem): boolean {
  return chatItem.message.type === ChatMessageType.UPLOAD;
}

function isAction(chatItem: ChatItem): boolean {
  return chatItem.message.type === ChatMessageType.ACTION;
}

export {
  ChatRequest,
  ChatCreateTextRequest,
  ChatCreateMessageRequest,
  ChatMessageCreatedResponse,
  ChatCreatedResponse,
  ChatUpdateMessageRequest,
  ChatDeleteMessageRequest,
  ChatMessageUpdatedResponse,
  ChatMessageType,
  ChatMessage,
  ChatItem,
  ChatText,
  ChatUpload,
  ChatUploadFile,
  ChatUploadFileStatus,
  ChatActionStatus,
  ChatAction,
  ChatActionButton,
  ChatUploadResponse,
  isChatText,
  isForm,
  isSystemItem,
  isUploadFile,
  isAction
};
