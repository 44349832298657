import React from 'react';
import Box from '@mui/material/Box';
import { Mirror } from '../../../types/mirror';
import { TaskDefinition } from '../../../types/task';
import { MirrorTaskEditorHeader } from '../MirrorTaskEditorHeader';
import HelpText from '../../../components/HelpText';
import ProgressCircle from '../../../components/ProgressCircle';

interface MirrorTaskEditorPanelProps {
  mirror: Mirror;
  task: TaskDefinition;
  help?: string;
  onClose: () => void;
  children: React.ReactNode;
  loading?: boolean;
}

export const MirrorTaskEditorPanel = ({
  mirror,
  task,
  onClose,
  children,
  help,
  loading
}: MirrorTaskEditorPanelProps) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateRows: '1fr auto', overflow: 'hidden' }}>
      <MirrorTaskEditorHeader mirror={mirror} onClose={onClose} task={task} />
      {help && <HelpText text={help} />}
      {loading ? <ProgressCircle /> : <Box sx={{ overflow: 'auto' }}>{children}</Box>}
    </Box>
  );
};
