import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useDeleteCupByIdMutation, useGetCupsQuery } from '../../state/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import { CupType } from '../../types/cup';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Cups() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: cups = [] } = useGetCupsQuery();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [deleteCup] = useDeleteCupByIdMutation();
  const [deleteCupId, setDeleteCupId] = useState<string>('');

  const handleClickAddCup = () => {
    navigate('/cups/add');
  };

  const getCupTypeTitle = (cupType: CupType): string => {
    if (cupType == CupType.EXTRACTION) {
      return 'Document';
    } else {
      return 'Tabular Data';
    }
  };

  const handleDeleteCup = async () => {
    await deleteCup(deleteCupId)
      .unwrap()
      .then(() => setNotificationMessage('CUP deleted'))
      .catch(() => {
        setNotificationMessage(`Failed to delete CUP`);
      })
      .finally(() => {
        setDeleteCupId('');
      });
  };

  const handleShowDeleteDialog = (event, id: string) => {
    event.stopPropagation();
    setDeleteCupId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteCupId('');
  };

  const handleRemoveNotificationMessage = () => {
    setNotificationMessage('');
  };

  return (
    <Box sx={{ p: 2, display: 'flex', gap: 1, flexDirection: 'column' }}>
      <SectionHeader title="CUPs">
        <Box sx={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickAddCup} size="medium">
            Create Cup
          </Button>
        </Box>
      </SectionHeader>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cups.map((cup) => (
              <TableRow key={cup.id} hover onClick={() => navigate(`/cups/${cup.id}`)}>
                <TableCell>{cup.name}</TableCell>
                <TableCell>{getCupTypeTitle(cup.type)}</TableCell>
                <TableCell>{cup.status}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, cup.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullScreen={fullScreen} open={deleteCupId.length > 0} onClose={handleDeleteCancel}>
        <DialogTitle id="responsive-dialog-title">{'Delete CUP'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this cup?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={() => handleDeleteCup()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notificationMessage.length > 0}
        autoHideDuration={4000}
        message={notificationMessage}
        onClose={handleRemoveNotificationMessage}
      />
    </Box>
  );
}
