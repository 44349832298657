import React, { useEffect, useRef, useState } from 'react';
import { ChatActionStatus, ChatUpload, ChatUploadFile, ChatUploadFileStatus } from '../../types/chat';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { ChatItemViewProps } from './ChatItemViewProps';
import { useDispatch } from 'react-redux';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { store } from '../../state/store';
import { chatUploadFilesThunk } from '../../state/chatSlice';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useDeleteChatMessageMutation } from '../../state/api';

export type AppDispatch = typeof store.dispatch;

const ChatUploadView = ({ item }: ChatItemViewProps) => {
  const [uploadStatus, setUploadStatus] = useState<ChatActionStatus>(null);
  const [uploadedFiles, setUploadedFiles] = useState<ChatUploadFile[]>([]);
  const [open, setOpen] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [deleteMessage] = useDeleteChatMessageMutation();

  useEffect(() => {
    if (item.data) {
      const chatUpload = item.data as ChatUpload;
      if (chatUpload.uiState) {
        setUploadStatus(chatUpload.uiState.status);
        setUploadedFiles(
          chatUpload.uiState.files.map((file) => {
            return {
              name: file,
              status: ChatUploadFileStatus.CREATED
            };
          })
        );
      } else {
        const createdFiles = chatUpload.files.filter((file) => file.status == ChatUploadFileStatus.CREATED);
        const failedFiles = chatUpload.files.filter((file) => file.status == ChatUploadFileStatus.FAILED);
        if (failedFiles.length > 0) {
          setUploadStatus(ChatActionStatus.FAILED);
        } else if (createdFiles.length > 0) {
          setUploadStatus(ChatActionStatus.RUNNING);
        } else {
          setUploadStatus(ChatActionStatus.COMPLETED);
        }
        setUploadedFiles(chatUpload.files);
      }
    }
  }, [item]);

  const renderUploadStatus = (status: ChatActionStatus) => {
    if (status == ChatActionStatus.FAILED) {
      return (
        <>
          <ErrorIcon color="error" fontSize="small" sx={{ mr: 1 }} /> File upload failed
        </>
      );
    } else if (status == ChatActionStatus.COMPLETED) {
      return (
        <>
          <DoneIcon fontSize="small" color="success" sx={{ mr: 1 }} /> File upload completed
        </>
      );
    } else {
      return (
        <>
          <CircularProgress size="1rem" sx={{ mr: 1 }} /> Uploading files
        </>
      );
    }
  };

  const renderFileStatus = (status: ChatUploadFileStatus) => {
    if (status == ChatUploadFileStatus.FAILED) {
      return <ErrorIcon color="error" fontSize="small" />;
    } else if (status == ChatUploadFileStatus.UPLOADED) {
      return <DoneIcon color="success" fontSize="small" />;
    } else {
      return <CircularProgress size="1rem" sx={{ mr: 1 }} />;
    }
  };

  const renderFile = (file: ChatUploadFile) => (
    <ListItem key={file.name}>
      {renderFileStatus(file.status)} {file.name}
    </ListItem>
  );
  const handleClick = () => {
    setOpen(!open);
  };

  const uploadFiles = (selectedFiles: FileList | null) => {
    if (selectedFiles && selectedFiles.length) {
      dispatch(
        chatUploadFilesThunk({
          chatId: item.message.chatId,
          chatMessage: item.message,
          message: '',
          files: selectedFiles
        })
      );
    }
    fileInputRef.current.value = '';
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length) {
      uploadFiles(selectedFiles);
    }
  };

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const handleThumbDown = async () => {
    await deleteMessage({
      chatId: item.message.chatId,
      chatMessageId: item.message.id
    })
      .unwrap()
      .catch(() => {
        console.error(`Failed to delete chat message`);
      });
  };

  return (
    <Box>
      {item.data && (
        <Box>
          <Typography sx={{ pb: 1 }}>{item.message.text}</Typography>
          <List
            sx={{ width: '100%', margin: '0', padding: '0' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={handleClick}
              sx={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box>{renderUploadStatus(uploadStatus)}</Box>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ maxHeight: 150, overflow: 'auto' }}>
                {uploadedFiles.map((file) => renderFile(file))}
              </List>
            </Collapse>
          </List>
        </Box>
      )}
      {!item.data && (
        <Box>
          <Typography sx={{ pb: 1 }}>
            {item.message.text}{' '}
            <IconButton
              aria-label="thumbs down"
              onClick={handleThumbDown}
              sx={{
                fontSize: 'inherit',
                marginLeft: 0,
                visibility: 'hidden' // disabled until improved usability
              }}
            >
              <ThumbDownIcon fontSize="inherit" />
            </IconButton>
          </Typography>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the file input
            onChange={handleFileChange}
            multiple
          />

          <Button type="button" onClick={handleAttachClick} aria-label="attach file">
            SELECT FILE
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default ChatUploadView;
