import React, { useEffect, useMemo, useState } from 'react';
import { Box, Snackbar } from '@mui/material';
import { TaskDefinition, TaskParameterDefinition, WorkflowId } from '../../../types/task';
import SelectableLabel from '../../../components/SelectableLabel';
import {
  useAddTaskInputParametersMutation,
  useCreateTaskInputParameterMutation,
  useDeleteTaskInputParameterMutation,
  useGetWorkflowDefinitionByIdQuery
} from '../../../state/api';
import InputParameterAddForm from './InputParameterAddForm';
import { useMirrorContext } from '../MirrorContextProvider';
import { SectionPanel } from '../../../components/SectionPanel';

interface InputParametersEditorProps {
  workflowId: WorkflowId;
  task: TaskDefinition;
  edit?: boolean;
  onAddParameters?: (parameters: TaskParameterDefinition[]) => void;
  onCreateParameter?: (name: string) => void;
  onDeleteParameter?: (parameter: TaskParameterDefinition) => void;
}

export function InputParametersEditor({
  workflowId,
  task,
  edit = false,
  onAddParameters,
  onCreateParameter,
  onDeleteParameter
}: InputParametersEditorProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data: workflow } = useGetWorkflowDefinitionByIdQuery(workflowId);
  const [createInputParameter] = useCreateTaskInputParameterMutation();
  const [addInputParameters] = useAddTaskInputParametersMutation();
  const [deleteTaskInputParameter] = useDeleteTaskInputParameterMutation();
  const [addInputs, setAddInputs] = useState<boolean>(edit);

  const { isLabelSelected, selectLabel, getAvailableOutputsForTask } = useMirrorContext();

  useEffect(() => {
    setAddInputs(edit);
  }, [edit]);

  const sortedInputs = useMemo(() => {
    return task.input.toSorted((a, b) => a.name.localeCompare(b.name));
  }, [task]);

  const availableOutputs = useMemo(() => {
    const mapped = task.input.flatMap((c) => c.mappedBy);
    return getAvailableOutputsForTask(task).filter((o) => !mapped.includes(o.parameterId));
  }, [workflow, task]);

  const handleOnAddInputParameter = async (parameters: TaskParameterDefinition[]) => {
    try {
      if (onAddParameters) {
        onAddParameters(parameters);
      } else {
        await addInputParameters({
          workflowId: workflowId,
          taskId: task.taskId,
          inputs: parameters.map((p) => {
            return {
              labelId: p.labelId,
              name: p.name,
              defaultValue: null,
              mappedBy: p.parameterId
            };
          })
        }).unwrap();
      }
      setAddInputs(edit);
    } catch (error) {
      if (error.status) {
        setErrorMessage('Creating new label failed.');
      }
    }
  };

  const handleCreateInputParameter = async (name: string) => {
    try {
      if (onCreateParameter) {
        onCreateParameter(name);
      } else {
        await createInputParameter({
          workflowId: workflowId,
          taskId: task.taskId,
          name: name
        }).unwrap();
      }
      setAddInputs(edit);
    } catch (error) {
      if (error.status) {
        setErrorMessage('Creating new label failed.');
      }
    }
  };

  const handleDeleteParameter = async (parameter: TaskParameterDefinition) => {
    try {
      if (onDeleteParameter) {
        onDeleteParameter(parameter);
      } else {
        await deleteTaskInputParameter({
          workflowId: workflowId,
          taskId: task.taskId,
          parameterId: parameter.parameterId
        }).unwrap();
      }
    } catch (error) {
      if (error.status) {
        setErrorMessage('Deleting label failed.');
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        {sortedInputs.map((parameter) => (
          <SelectableLabel
            key={parameter.parameterId}
            name={parameter.description.title}
            selected={isLabelSelected(parameter)}
            onClick={() => selectLabel(parameter)}
            fulfilled={parameter.fulfilled}
            onDelete={() => handleDeleteParameter(parameter)}
          />
        ))}
        {sortedInputs.length > 0 && !addInputs && (
          <SelectableLabel name="Add" variant="add" onClick={() => setAddInputs(true)} />
        )}
      </Box>

      {((sortedInputs.length === 0 && availableOutputs.length > 0) || addInputs) && (
        <SectionPanel title="Select Labels" variant="subtitle2">
          <InputParameterAddForm
            availableOutputs={availableOutputs}
            onAddParameters={handleOnAddInputParameter}
            onCancel={() => setAddInputs(false)}
            onCreateParameter={handleCreateInputParameter}
          />
        </SectionPanel>
      )}
      <Snackbar open={errorMessage.length > 0} autoHideDuration={4000} message={errorMessage} />
    </Box>
  );
}
