import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, Snackbar, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Mirror } from '../../../types/mirror';
import { TaskDefinition, TaskLogicExpression } from '../../../types/task';
import {
  useDeleteLogicTaskExpressionMutation,
  useGetLogicTaskExpressionsQuery,
  useSaveLogicTaskExpressionMutation
} from '../../../state/api';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { SectionPanel } from '../../../components/SectionPanel';
import DeleteIcon from '@mui/icons-material/Delete';
import SectionTable from '../../../components/SectionTable';
import LogicExpressionEditor from './logic/LogicExpressionEditor';
import CreateButton from '../../../components/CreateButton';

interface MirrorLogicTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorLogicTaskEditor = ({ mirror, task, onClose }: MirrorLogicTaskEditorProps) => {
  const { data: existing = { taskId: task.taskId, expressions: [] }, isLoading } = useGetLogicTaskExpressionsQuery({
    workflowId: mirror.workflowId,
    taskId: task.taskId
  });
  const [deleteLogicTaskExpression] = useDeleteLogicTaskExpressionMutation();
  const [saveLogicTaskExpression] = useSaveLogicTaskExpressionMutation();

  const [selectedExpression, setSelectedExpression] = useState<TaskLogicExpression>(null);
  const [expressions, setExpression] = useState<TaskLogicExpression[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (selectedExpression) {
      const update = selectedExpression.mappedTo
        ? existing.expressions.find((e) => e.mappedTo === selectedExpression.mappedTo)
        : existing.expressions.find((e) => !expressions.some((exp) => exp.mappedTo === e.mappedTo));
      if (update) {
        setSelectedExpression(update);
      }
      setExpression(existing.expressions);
    } else if (!isLoading) {
      if (existing.expressions.length === 0) {
        openAddExpressionForm();
      } else {
        setSelectedExpression(existing.expressions[0]); // hiding expressions list for now
      }
    }
  }, [selectedExpression, existing]);

  const openAddExpressionForm = () => {
    const newExpression: TaskLogicExpression = {
      expression: '',
      logic: '',
      mappedTo: ''
    };

    setSelectedExpression(newExpression);
  };

  const handleOnEditExpression = (expression: TaskLogicExpression) => {
    setSelectedExpression(expression);
  };

  const handleCancel = () => {
    setSelectedExpression(null);
  };

  const handleSaveExpression = async (save: TaskLogicExpression) => {
    try {
      setSelectedExpression(save);

      const mappedTo = selectedExpression.mappedTo === '' ? save.mappedTo : selectedExpression.mappedTo;

      await saveLogicTaskExpression({
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        mappedTo: mappedTo,
        update: save
      }).unwrap();
    } catch (error) {
      setErrorMessage('Failed to save logic expression');
    }
  };

  const handleDeleteExpression = async (event, expression: TaskLogicExpression) => {
    event.stopPropagation();
    try {
      await deleteLogicTaskExpression({
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        mappedTo: expression.mappedTo
      }).unwrap();
    } catch (error) {
      console.error('Failed to delete logic expressions', error);
    }
  };

  return (
    <MirrorTaskEditorPanel mirror={mirror} task={task} onClose={onClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {!selectedExpression && (
            <SectionPanel title="Expressions">
              <SectionTable>
                <TableHead>
                  <TableRow component="th" scope="row">
                    <TableCell>Output Label</TableCell>
                    <TableCell>Has Logic</TableCell>
                    <TableCell>Is Ready</TableCell>
                    <TableCell align="right">
                      <CreateButton title="Add Logic" onClick={() => openAddExpressionForm()} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {existing.expressions.map((expression) => (
                    <TableRow key={expression.logic} hover onClick={() => handleOnEditExpression(expression)}>
                      <TableCell>{expression.mappedToName}</TableCell>
                      <TableCell>{expression.logic.length > 0 ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{expression.expression.length > 0 ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={(event) => handleDeleteExpression(event, expression)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </SectionTable>
            </SectionPanel>
          )}
          {selectedExpression && (
            <LogicExpressionEditor
              workflowId={mirror.workflowId}
              task={task}
              expression={selectedExpression}
              onClose={handleCancel}
              onSave={handleSaveExpression}
            />
          )}
        </>
      )}
      <Snackbar open={errorMessage.length > 0} autoHideDuration={4000} message={errorMessage} />
    </MirrorTaskEditorPanel>
  );
};
