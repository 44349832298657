import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface ProgressCircleProps {
  label?: string;
  children: React.ReactNode;
  loading: boolean;
}

const ProgressCircle = ({ label, children, loading }: ProgressCircleProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            backdropFilter: 'blur(3px)'
          }}
        >
          <CircularProgress />
          <Box>{label && <Typography variant="caption">{label}</Typography>}</Box>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default ProgressCircle;
