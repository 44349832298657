import { Box, Theme } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';

interface ImageProps {
  alt: string;
  src: string;
  sx?: SxProps<Theme>;
}

const Picture = ({ alt, src, sx }: ImageProps) => {
  return (
    <Box
      component={'img'}
      src={src}
      alt={alt}
      sx={
        sx
          ? sx
          : {
              width: '100%',
              height: '100%'
            }
      }
    />
  );
};

export default Picture;
