import { Box, Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TaskLogicExpression } from '../../../../types/task';

interface LogicExpressionFormProps {
  expression: TaskLogicExpression;
  onSave: (save: TaskLogicExpression) => void;
  onCancel: () => void;
}

export const LogicExpressionStep = ({ expression, onSave, onCancel }: LogicExpressionFormProps) => {
  const [logic, setLogic] = useState<string>('');
  const [exp, setExp] = useState<string>('');
  const [aiLogicEnabled, setAiLogicEnabled] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setLogic(expression.logic ? expression.logic : '');
    setExp(expression.expression ? expression.expression : '');
    setSaving(false);
    setEdit(!expression.logic || expression.logic.length === 0);
  }, [expression]);

  const handleLogicUpdate = (update: string) => {
    setLogic(update);
  };
  const handleExpressionUpdate = (update: string) => {
    setExp(update);
  };

  const handleCancel = () => {
    setLogic(expression.logic ? expression.logic : '');
    setExp(expression.expression ? expression.expression : '');
    onCancel();
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = () => {
    setSaving(true);
    setEdit(false);
    onSave({
      ...expression,
      logic,
      expression: exp
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%'
      }}
    >
      <Typography variant="body2">
        Describe the logic you wish to apply on the selected inputs and outputs you have chosen.
      </Typography>
      <TextField
        label="Describe the logic"
        multiline
        value={logic}
        onChange={(e) => handleLogicUpdate(e.target.value)}
        rows={8}
        disabled={logic.length > 0 && !edit}
        sx={{
          flex: 1
        }}
      />

      {aiLogicEnabled && (
        <TextField
          label="JavaScript Expression"
          multiline
          value={exp}
          onChange={(e) => handleExpressionUpdate(e.target.value)}
          rows={8}
          disabled
          sx={{
            flex: 1
          }}
        />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        {edit && (
          <>
            <Button variant="contained" size="small" disabled={saving} onClick={() => handleSave()}>
              Save
            </Button>
            <Button variant="outlined" size="small" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </>
        )}
        {!edit && (
          <Button variant="contained" size="small" disabled={saving} onClick={() => handleEdit()}>
            Edit
          </Button>
        )}
        <FormControlLabel
          control={
            <Switch
              disabled={expression.expression.length === 0}
              checked={aiLogicEnabled}
              onChange={() => setAiLogicEnabled(!aiLogicEnabled)}
              color="primary"
            />
          }
          label="Show Expression"
        />
      </Box>
    </Box>
  );
};
