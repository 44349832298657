import React from 'react';
import { Container } from '@mui/material';

const SiteDown = () => {
  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 10 }}>
      Site is down
    </Container>
  );
};

export default SiteDown;
