import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { TaskDefinition, TaskParameter, WorkflowId } from '../../../types/task';
import SelectableLabel from '../../../components/SelectableLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCreateTaskOutputParameterMutation, useDeleteTaskOutputParameterMutation } from '../../../state/api';
import AddIcon from '@mui/icons-material/Add';
import { Label } from '../../../types/label';
import { useMirrorContext } from '../MirrorContextProvider';
import SectionTable from '../../../components/SectionTable';

interface OutputParametersEditorProps {
  workflowId: WorkflowId;
  task: TaskDefinition;
  readOnly: boolean;
}

export function OutputParametersEditor({ workflowId, task, readOnly }: OutputParametersEditorProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [deleteTaskOutputParameter] = useDeleteTaskOutputParameterMutation();
  const [postTaskOutputParameter] = useCreateTaskOutputParameterMutation();
  const [selectedLabel, setSelectedLabel] = useState<Label>(null);
  const [labelName, setLabelName] = useState('');
  const { isLabelSelected, selectLabel } = useMirrorContext();

  const handleDelete = async (parameter: TaskParameter) => {
    try {
      await deleteTaskOutputParameter({
        workflowId: workflowId,
        parameterId: parameter.parameterId,
        taskId: task.taskId
      }).unwrap();
    } catch (error) {
      setErrorMessage('Removing parameter failed.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const handleAddParameterCancel = () => {
    setSelectedLabel(null);
    setIsFormOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await postTaskOutputParameter({
        workflowId: workflowId,
        taskId: task.taskId,
        labelName: labelName == '' ? null : labelName,
        labelId: selectedLabel ? selectedLabel.labelId : null,
        defaultValue: null
      }).unwrap();
      setIsFormOpen(false);
    } catch (error) {
      if (error.status) {
        setErrorMessage('Creating new output parameter failed.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
    setSelectedLabel(null);
  };

  return (
    <Box>
      {isFormOpen && (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              m: 2
            }}
          >
            <Typography variant={'h6'}>Select Output</Typography>
            <FormControl variant="outlined" sx={{ gap: 1 }}>
              <TextField
                label="Label Name"
                variant="outlined"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
                required
                autoFocus
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              <Button type="submit" variant="contained" color="primary" disabled={labelName == ''}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleAddParameterCancel}>
                Cancel
              </Button>
            </Box>
            {errorMessage}
          </Box>
        </form>
      )}

      {!isFormOpen && (
        <SectionTable>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">
                {!readOnly && (
                  <IconButton color="primary" edge="start" onClick={() => setIsFormOpen(true)} size="medium">
                    <AddIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {task.output.map((parameter) => (
              <TableRow
                key={parameter.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover .deleteIcon': {
                    opacity: 1
                  }
                }}
              >
                <TableCell component="th" scope="row">
                  <SelectableLabel
                    name={parameter.description.title}
                    selected={isLabelSelected(parameter)}
                    onClick={() => selectLabel(parameter)}
                  />
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    transition: 'opacity 300ms ease',
                    '.deleteIcon': {
                      opacity: 0
                    }
                  }}
                >
                  {!readOnly && (
                    <DeleteIcon
                      onClick={() => handleDelete(parameter)}
                      sx={{ cursor: 'pointer' }}
                      className="deleteIcon"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
        </SectionTable>
      )}
    </Box>
  );
}
