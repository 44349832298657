import { Autocomplete, Box, Button, createFilterOptions, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import SelectableLabel from '../../../components/SelectableLabel';
import { Label } from '../../../types/label';
import CreateButton from '../../../components/CreateButton';

interface LabelOptionType {
  inputValue?: string;
  title: string;
}

interface LabelOptionValidation {
  ok: boolean;
  validationError?: string;
}

const filter = createFilterOptions<LabelOptionType>();

interface ParameterAutoCompleteProps {
  availableLabels: Label[];
  isValidateLabelName?: (title: string) => LabelOptionValidation;
  selected?: string;
  onCreateParameter?: (name: string) => void;
  onLabelChange?: (label: Label) => void;
  onLabelCreate?: (title: string) => void;
  onLabelRemove?: () => void;
  onCancel?: () => void;
  openOnFocus?: boolean;
  label: string;
}

export const ParameterAutoComplete = ({
  selected,
  availableLabels,
  onLabelChange,
  onLabelCreate,
  onLabelRemove,
  onCancel,
  label,
  isValidateLabelName = () => {
    return {
      ok: true
    };
  }
}: ParameterAutoCompleteProps) => {
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [showSelect, setShowSelect] = useState<boolean>(false);

  useEffect(() => {
    handleOnCancel();
  }, [availableLabels, selected]);

  const labelOptions: LabelOptionType[] = useMemo(() => {
    return availableLabels.map((label) => {
      return {
        title: label.description.title
      };
    });
  }, [availableLabels]);

  const validation = useMemo(() => {
    return isValidateLabelName(selectedLabel);
  }, [selectedLabel]);

  const isCreateEnabled = (): boolean => {
    if (validation.ok) {
      return !availableLabels.some((l) => l.description.title === selectedLabel);
    }

    return false;
  };

  const handleOnCancel = () => {
    if (selected) {
      const definition = availableLabels.find((l) => l.description.title === selected);
      if (definition) {
        setSelectedLabel(definition.description.title);
        setShowSelect(false);
      }
    }

    if (onCancel) {
      onCancel();
    }
  };

  const handleOnLabelChange = (label: Label) => {
    if (onLabelChange) {
      onLabelChange(label);
    }
  };

  const handleOnLabelRemove = () => {
    if (onLabelRemove) {
      onLabelRemove();
    }
  };

  const handleOnChange = (newValue: string | LabelOptionType) => {
    if (typeof newValue === 'string') {
      setSelectedLabel(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      handleOnCreate(newValue.inputValue);
    } else {
      handleOnOptionChange(newValue);
    }
  };

  const handleOnOptionChange = (update: LabelOptionType) => {
    const title = update ? update.title : '';

    if (selectedLabel === title) {
      handleOnCancel();
    } else {
      setSelectedLabel(title);
      const label = availableLabels.find((l) => l.description.title === title);

      if (label) {
        handleOnLabelChange(label);
      } else {
        handleOnLabelRemove();
      }
    }
    setShowSelect(false);
  };

  const handleOnCreate = (name: string) => {
    if (onLabelCreate) {
      onLabelCreate(name);
    }
  };

  if (selectedLabel.length > 0 && !showSelect) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
      >
        <SelectableLabel name={selectedLabel} />
        <Button variant="outlined" size="small" color="secondary" onClick={() => setShowSelect(true)}>
          Edit
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'flex-start'
      }}
    >
      <Autocomplete
        freeSolo
        clearOnEscape
        openOnFocus={true}
        size="small"
        value={selectedLabel}
        options={labelOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={true}
            label={label}
            helperText={validation.validationError || ' '}
            error={!validation.ok}
          />
        )}
        fullWidth
        onChange={(_event, newValue) => handleOnChange(newValue)}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const filtered = filter(options, params);
          if (filtered.length === 0) {
            setSelectedLabel(inputValue);
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Create "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          pt: 0.5
        }}
      >
        <CreateButton
          size="small"
          title="Create"
          disabled={!isCreateEnabled()}
          onClick={() => handleOnCreate(selectedLabel)}
        />
        <Button variant="outlined" size="small" color="secondary" onClick={() => handleOnCancel()}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
