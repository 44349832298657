import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import authReducer from './authSlice';
import clientReducer from './clientSlice';
import { authStateListener } from './authStateListener';

const auth = JSON.parse(localStorage.getItem('auth') || 'null');

export const store = configureStore({
  preloadedState: {
    auth: auth === null ? { token: null } : auth
  },
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    client: clientReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, authStateListener.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
