import { Chip } from '@mui/material';
import React from 'react';
import { TaskStatus } from '../types/task';

interface TaskStatusChipProps {
  status: TaskStatus;
}

export const TaskStatusChip = ({ status }: TaskStatusChipProps) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'DONE':
        return 'success';
      case 'IN_PROGRESS':
        return 'primary';
      case 'FAILED':
        return 'error';
      default:
        return 'default';
    }
  };

  return <Chip label={status} color={getStatusColor(status)} />;
};
