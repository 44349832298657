import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import { CupFilterConfig, CupFilterType } from '../../../types/cup';
import { usePutCupFilterMutation } from '../../../state/api';

export interface CupTabularDataFilterFormProps {
  cupId: string;
  filterConfig?: CupFilterConfig;
  onCompleted: () => void;
  onCancel: () => void;
}

export const CupTabularDataFilterForm = ({
  cupId,
  filterConfig: filter,
  onCompleted,
  onCancel
}: CupTabularDataFilterFormProps) => {
  const [putCupFilter] = usePutCupFilterMutation();
  const [filterId, setFilterId] = useState<string>(filter && filter.filterId ? filter.filterId : null);
  const [regex, setRegex] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const savedFilter = await putCupFilter({
        filterId: filterId,
        cupId: cupId,
        name: 'Tabular Data Filename Filter',
        filterType: CupFilterType.FILENAME_FILTER,
        parameters: getFilterParameters(),
        createdAt: filter && filter.createdAt ? filter.createdAt : null
      }).unwrap();

      if (savedFilter) {
        setFilterId(savedFilter.filterId);
        onCompleted();
      }
    } catch (error) {
      setErrorMessage('Creating new label failed.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const getFilterParameters = () => {
    const parameters: Record<string, string> = {};
    parameters['regex'] = regex;
    return parameters;
  };

  useEffect(() => {
    if (filter && filter.filterType == CupFilterType.FILENAME_FILTER) {
      setRegex(filter.parameters['regex']);
    }
  }, [filter]);

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          width: '100%'
        }}
      >
        <Typography variant={'h6'}>{filter ? 'Edit' : 'Create'} Filename Filter</Typography>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <TextField
            name="regex"
            label="Filname Regular Expression"
            value={regex}
            onChange={(e) => setRegex(e.target.value)}
          />
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              pt: 2,
              width: '100%'
            }}
          >
            {regex && regex.length > 0 && (
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            )}
            <Button variant="outlined" color="secondary" onClick={() => onCancel()}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
