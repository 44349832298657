import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Alert, Autocomplete, Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material';
import { useSignupMutation } from '../../state/api';
import { SignupRequest } from '../../state/model';
import { useAuth } from '../../hooks/useAuth';
import { Country, countryData } from '../../state/country';

const Signup = () => {
  const user = useAuth();
  const [signup, { isLoading }] = useSignupMutation();
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({ signup: '', yearOfBirth: '' });

  const [formValues, setFormValues] = useState<SignupRequest>({
    yearOfBirth: 0,
    country: ''
  });

  function getCountryCodeFromLocale(): string | null {
    const userLocale = navigator.language || 'en-US';
    return userLocale.split('-')[1].toUpperCase();
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    const validationYear = new Date().getFullYear() - 5;
    const newErrors = { ...errors };
    const regEx = /^\d{4}$/; // 4-digit number
    if (!regEx.test(value) || Number(value) > validationYear || Number(value) < 1900) {
      newErrors.yearOfBirth = 'Invalid Year of Birth';
    } else {
      newErrors.yearOfBirth = '';
    }

    setErrors(newErrors);
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    signup(formValues)
      .unwrap()
      .catch((error) => {
        const newErrors = { ...errors };

        if (error.status === 401) {
          newErrors.signup = 'Unauthorized';
        } else if (error.data) {
          newErrors.signup = error.data.message;
        } else {
          newErrors.signup = 'Unknown error';
        }

        setErrors(newErrors);
        setShowError(true);
      });
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 10 }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '2rem'
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" component="h3">
          Signup
        </Typography>
        <TextField
          id="yearOfBirth"
          name="yearOfBirth"
          label="Year of birth"
          variant="outlined"
          error={!!errors.yearOfBirth}
          helperText={errors.yearOfBirth}
          fullWidth
          disabled={isLoading}
          onChange={handleTextFieldChange}
        />
        <Autocomplete
          id="country"
          disabled={isLoading}
          options={countryData}
          getOptionLabel={(option: Country) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          value={countryData.find((country) => country.name === formValues.country) || null}
          onChange={(event: never, newValue: Country | null) => {
            setFormValues({
              ...formValues,
              country: newValue ? newValue.name : formValues.country
            });
          }}
          renderInput={(params) => <TextField {...params} label="Country" variant="outlined" fullWidth />}
          defaultValue={countryData.find((country) => country.code === getCountryCodeFromLocale()) || null}
        />
        <Button
          variant="contained"
          fullWidth
          disabled={isLoading || Object.entries(errors).some(([key, value]) => key !== 'signup' && value !== '')}
          type="submit"
        >
          Signup
        </Button>
      </Box>
      <Snackbar open={showError} autoHideDuration={6000}>
        <Alert severity="warning" sx={{ width: '100%' }}>
          {errors.signup}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;
