import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { TaskDefinition, TaskLogicExpression } from '../../../../types/task';
import { useMirrorContext } from '../../MirrorContextProvider';
import { ParameterAutoComplete } from '../ParameterAutoComplete';
import { Label } from '../../../../types/label';

interface LogicExpressionFormProps {
  task: TaskDefinition;
  expression: TaskLogicExpression;
  onChange: (parameter: Label) => void;
  onCreate: (name: string) => void;
}

export const LogicOutputParameterStep = ({ task, expression, onChange, onCreate }: LogicExpressionFormProps) => {
  const { availableLabels } = useMirrorContext();

  const outputLabel = useMemo(() => {
    return task.output.find((o) => o.parameterId === expression.mappedTo);
  }, [task.output, expression]);

  const outputLabelName = useMemo(() => {
    if (outputLabel) {
      return outputLabel.description.title;
    } else {
      return '';
    }
  }, [outputLabel]);

  const inputLabelNames = useMemo(() => task.input.map((i) => i.description.title), [task.input]);

  const availableOutputs: Label[] = useMemo(() => {
    const inputLabelIds = new Set(task.input.map((i) => i.labelId));
    const selectedInputs = availableLabels.filter((o) => !inputLabelIds.has(o.labelId));

    if (outputLabel && !selectedInputs.some((i) => i.labelId === outputLabel.labelId)) {
      return [...selectedInputs, outputLabel].sort((a, b) => a.description.title.localeCompare(b.description.title));
    }

    return selectedInputs;
  }, [availableLabels, task.input, outputLabel]);

  const handleLabelChange = (label: Label) => {
    onChange(label);
  };

  const handleLabelCreate = (name: string) => {
    onCreate(name);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography variant="body2">
        The logic outputs data into a new label. Chose an existing label, or create a new one.
      </Typography>
      <ParameterAutoComplete
        selected={outputLabelName}
        availableLabels={availableOutputs}
        isValidateLabelName={(title) => {
          if (title.length === 0) {
            return { ok: false, validationError: 'Label name can not be empty' };
          } else if (inputLabelNames.includes(title)) {
            return { ok: false, validationError: 'Label name is used as input' };
          } else {
            return { ok: true };
          }
        }}
        onLabelChange={handleLabelChange}
        onLabelCreate={handleLabelCreate}
        label="Output Label"
      />
    </Box>
  );
};
