import React, { useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import SystemAvatar from '../SystemAvatar';
import UserAvatar from '../UserAvatar';
import { useAuth } from '../../hooks/useAuth';
import {
  ChatAction,
  ChatItem,
  ChatMessageType,
  isAction,
  isChatText,
  isForm,
  isSystemItem,
  isUploadFile
} from '../../types/chat';
import ChatFormView from './ChatFormView';
import ChatTextView from './ChatTextView';
import ChatUploadView from './ChatUploadView';
import ChatActionView from './ChatActionView';
import EditIcon from '@mui/icons-material/Edit';

interface ChatMessageProps {
  item: ChatItem;
  isLastChatMessage?: boolean;
  isThreadItem?: boolean;
}

const ChatMessageView = ({ item, isLastChatMessage = false, isThreadItem = false }: ChatMessageProps) => {
  const auth = useAuth();
  const theme = useTheme();
  const [isHoovering, setIsHoovering] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const allowTextEdit = (item: ChatItem) => {
    return isLastChatMessage && item.message.type == ChatMessageType.TEXT && !isSystemItem(item);
  };

  const handleEditStart = () => {
    setEdit(true);
  };

  const handleEditDone = () => {
    setEdit(false);
  };

  const renderChatItem = (item: ChatItem) => {
    if (isChatText(item)) {
      return <ChatTextView message={item.message} edit={edit} onSaved={handleEditDone} onCancel={handleEditDone} />;
    } else if (isForm(item)) {
      return <ChatFormView item={item} />;
    } else if (isUploadFile(item)) {
      return <ChatUploadView item={item} />;
    } else if (isAction(item)) {
      return <ChatActionView message={item.message} action={item.data as ChatAction} />;
    }

    return <Typography variant="body1">Unknown chat message</Typography>;
  };

  const renderAvatar = () => {
    return isSystemItem(item) ? (
      <SystemAvatar small={isThreadItem} />
    ) : (
      <UserAvatar user={auth.user} small={isThreadItem} />
    );
  };

  return (
    <Box
      onMouseOver={() => setIsHoovering(true)}
      onMouseOut={() => setIsHoovering(false)}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 11fr 1fr'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 1
        }}
      >
        {renderAvatar()}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          ...(!isThreadItem && {
            borderRight: 1,
            borderColor: !isThreadItem && isHoovering ? theme.palette.secondary.main : 'transparent',
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
            borderRadius: theme.spacing(2),
            p: 1
          })
        }}
      >
        <Box>{renderChatItem(item)}</Box>
        {item.threadItems.length > 0 &&
          item.threadItems.map((item, index) => <ChatMessageView key={`${index}`} item={item} isThreadItem />)}
      </Box>

      <Box
        sx={{
          pt: 1
        }}
      >
        {allowTextEdit(item) && isHoovering && (
          <IconButton size="small" aria-label="edit text" onClick={handleEditStart}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessageView;
