import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  useMediaQuery
} from '@mui/material';
import Chat from '../../components/chat/Chat';
import FlowGraphView, { FlowGraphEvent, FlowGraphEventType } from '../../components/flowgraph/FlowGraphView';
import { TaskDefinition, TaskType, WorkflowDefinition } from '../../types/task';
import { Mirror, MirrorState } from '../../types/mirror';
import { useCreateTaskMutation, useDeleteTaskMutation } from '../../state/api';
import { ProcessStatus } from '../../types/process';
import ProgressOverlay from '../../components/ProgressOverlay';
import { isMobile } from 'react-device-detect';

interface MirrorGraphPanelProps {
  mirror: Mirror;
  mirrorState: MirrorState;
  workflow: WorkflowDefinition;
  onTaskSelected: (task: TaskDefinition) => void;
  showGraph: boolean;
}

export const MirrorGraphPanel = ({
  mirror,
  mirrorState,
  workflow,
  onTaskSelected,
  showGraph
}: MirrorGraphPanelProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [deleteTaskId, setDeleteTaskId] = useState<string>('');
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const [deleteTask] = useDeleteTaskMutation();
  const [createTask] = useCreateTaskMutation();
  const handleGraphEvent = (event: FlowGraphEvent) => {
    if (workflow) {
      switch (event.type) {
        case FlowGraphEventType.ON_CLICK:
          onTaskSelected(workflow.tasks.find((task) => task.taskId == event.nodeId));
          break;

        case FlowGraphEventType.ON_DELETE:
          handleShowDeleteDialog(event.nodeId);
          break;

        case FlowGraphEventType.ON_ADD_AGGREGATE:
          handleCreateAggregateTask(event.nodeId);
          break;
      }
    }
  };

  const handleDeleteTask = async () => {
    await deleteTask({
      workflowId: mirror.workflowId,
      taskId: deleteTaskId
    })
      .unwrap()
      .then(() => setNotificationMessage('Task deleted'))
      .catch((error) => {
        setNotificationMessage(`Failed to delete task`);
        console.error(`Failed to remove task with ID ${deleteTaskId}`, error);
      })
      .finally(() => {
        setDeleteTaskId('');
      });
  };

  const handleCreateAggregateTask = async (selected: string) => {
    await createTask({
      workflowId: mirror.workflowId,
      selectedTaskId: selected,
      taskType: TaskType.AGGREGATE
    })
      .unwrap()
      .then(() => setNotificationMessage('Aggregate task added.'))
      .catch(() => {
        setNotificationMessage(`Failed to add aggregate task`);
      })
      .finally(() => {
        setDeleteTaskId('');
      });
  };

  const handleShowDeleteDialog = (id: string) => {
    setDeleteTaskId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteTaskId('');
  };

  const handleRemoveNotificationMessage = () => {
    setNotificationMessage('');
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          height: '100%',
          gridTemplateColumns: { xs: 'none', sm: '35rem auto' },
          overflow: 'hidden'
        }}
      >
        {(!isMobile || !showGraph) && <Chat chatId={mirror.chatId} />}
        {(!isMobile || showGraph) && (
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <ProgressOverlay loading={!mirrorState || mirrorState.workflow === ProcessStatus.LOADING}>
              {workflow && <FlowGraphView flow={workflow} onEvent={handleGraphEvent} />}
            </ProgressOverlay>
          </Box>
        )}
      </Box>

      <Dialog fullScreen={fullScreen} open={deleteTaskId.length > 0} onClose={handleDeleteCancel}>
        <DialogTitle id="responsive-dialog-title">{'Delete Task'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this task?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={() => handleDeleteTask()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notificationMessage.length > 0}
        autoHideDuration={4000}
        message={notificationMessage}
        onClose={handleRemoveNotificationMessage}
      />
    </>
  );
};
