import React, { useEffect, useState } from 'react';
import { Form as FormData, FormStatus } from '../../types/form';
import FormEditor from '../FormEditor';
import { Box, Collapse, List, ListItemButton, Typography } from '@mui/material';
import { ChatItemViewProps } from './ChatItemViewProps';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ChatFormView = ({ item }: ChatItemViewProps) => {
  const formData = item.data as FormData;
  const [completedForm, setCompletedForm] = useState<FormData>();
  const [open, setOpen] = React.useState(false);
  const formOutputNames: Set<string> = new Set(['formRejected']);

  useEffect(() => {
    if (formData && formData.status == FormStatus.COMPLETED) {
      setCompletedForm(formData);
    }
  });

  const onCompleted = (form: FormData) => {
    setCompletedForm(form);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const wasFormRejected = () => {
    const formRejectedField = formData.fields.find((field) => formOutputNames.has(field.name));
    return formRejectedField && formRejectedField.value == 'true';
  };

  const FormFields = () => {
    const fields = formData.fields.filter((field) => !formOutputNames.has(field.name));
    return fields.map((field, index) => (
      <div key={index}>
        <Typography variant="subtitle1" component="div">
          {field.description}:
        </Typography>
        <Typography variant="body1" component="div">
          {field.value || 'No value provided.'}
        </Typography>
      </div>
    ));
  };

  const ChatItemTitle = () => {
    if (completedForm && wasFormRejected()) {
      return 'Form was rejected by user.';
    } else {
      return 'Form has successfully been submitted.';
    }
  };

  return (
    <Box m="auto">
      <FormEditor initialFormData={formData} onCompleted={onCompleted} chatItem={item} />
      {completedForm && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <List
            sx={{ width: '100%', bgcolor: 'background.paper', margin: '0', padding: '0' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              onClick={handleClick}
              sx={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <ChatItemTitle />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <Typography variant="body1" component="div">
                  {formData.title}
                </Typography>
                <Typography variant="body2" component="div">
                  {formData.description}
                </Typography>
                <FormFields />
              </Box>
            </Collapse>
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ChatFormView;
