import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import SectionTable from '../../components/SectionTable';
import StatusDot, { TrafficLightStatus } from '../../components/StatusDot';
import { DocumentId, StandardLabel, TextExtraction } from '../../types/annotation';
import { findFirstTextExtractionByLabelName, normalisedExtractionValue } from '../../types/utils/extractions';
import { getShortenedFilename } from '../../utils/string.utils';
import { DocumentWithExtractions } from './Documents';

interface DocumentKpiViewProps {
  isLoading: boolean;
  documents: DocumentWithExtractions[];
  getExtractionValue: (documentId: DocumentId, label: StandardLabel) => string;
}

interface Kpi {
  id: string;
  name: string;
  description: string;
  target?: string;
  actual?: string;
  status?: TrafficLightStatus | null;
}

interface DocumentKpi {
  documentId: string;
  name: string;
  counterpart: string;
  kpis: Kpi[];
}

const LabelValue = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'start',
        gridTemplateColumns: '2rem auto',
        columnGap: 0.5
      }}
    >
      <span className="font-bold">{label}</span>
      <span>{value}</span>
    </Box>
  );
};

const DocumentsKpiView = ({ documents, isLoading, getExtractionValue }: DocumentKpiViewProps) => {
  const toDocumentKpi = (doc: DocumentWithExtractions): DocumentKpi => {
    const kpis = doc.extractions.filter((extraction: TextExtraction) => extraction.labelName === 'Kpi');

    const asValue = (status: TrafficLightStatus): number => {
      if (status === 'RED') {
        return 1;
      } else if (status === 'AMBER') {
        return 2;
      } else if (status === 'GREEN') {
        return 3;
      }
      return status === null ? 0 : -1;
    };

    const fakeStatus = (diff?: number) => {
      if (diff >= 0.9) {
        return 'GREEN';
      } else if (diff >= 0.7) {
        return 'AMBER';
      } else if (diff) {
        return 'RED';
      }

      return undefined;
    };

    const fakeKpis = kpis
      .map<Kpi>((e) => {
        const target = normalisedExtractionValue(findFirstTextExtractionByLabelName(e, 'KpiLimit'));
        const splitted = /(\D*)(\d*)(.*)/.exec(target);
        const targetNumber = splitted[2] ?? '';
        const fakeActualNumber = targetNumber ? Math.floor((Math.random() * +0.4 + 0.6) * +targetNumber) : undefined;
        const isNotMapped = Math.random() > 0.8;
        const status = isNotMapped ? null : fakeStatus(fakeActualNumber / +targetNumber);
        return {
          id: crypto.randomUUID(),
          name: e.labelTitle,
          description: normalisedExtractionValue(e),
          target: target,
          actual:
            status === null
              ? 'There are no workflows created for this KPI'
              : typeof status === 'undefined'
                ? '-'
                : fakeActualNumber + splitted[3],
          status: status
        };
      })
      .sort((a, b) => asValue(a.status) - asValue(b.status));

    return {
      documentId: doc.document.id,
      name: doc.document.name,
      counterpart: getExtractionValue(doc.document.id, StandardLabel.Supplier),
      kpis: fakeKpis
    };
  };

  const kpiDocuments = useMemo(() => {
    return documents.map((d) => toDocumentKpi(d)).filter((d) => d.kpis.length > 0);
  }, [documents]);

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>KPIs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading &&
          kpiDocuments?.map((doc) => (
            <TableRow key={doc.documentId}>
              <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="body1" sx={{ ':hover': { textDecoration: 'underline' } }}>
                    <Link to={`/documents/${doc.documentId}`}>{getShortenedFilename(doc.name, 50)}</Link>
                  </Typography>
                  <Typography variant="caption">{doc.counterpart}</Typography>
                </Box>
              </TableCell>
              <TableCell>
                {doc.kpis.map((kpi, idx, arr) => (
                  <React.Fragment key={kpi.id}>
                    <StatusDot
                      status={kpi.status}
                      tooltip={
                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, p: 1 }}>
                          <div>
                            <Typography variant="body2">{kpi.description}</Typography>
                          </div>
                          {kpi.target && <LabelValue label="Target" value={kpi.target} />}
                          {kpi.actual && <LabelValue label="Actual" value={kpi.actual} />}
                        </Box>
                      }
                    />
                    {arr[idx + 1] && kpi.status !== arr[idx + 1].status && <br />}
                  </React.Fragment>
                ))}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </SectionTable>
  );
};

export default DocumentsKpiView;
