import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface ProgressCircleProps {
  label?: string;
}

const ProgressCircle = ({ label }: ProgressCircleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <CircularProgress />
      <Box>{label && <Typography variant="caption">{label}</Typography>}</Box>
    </Box>
  );
};

export default ProgressCircle;
