import { Chip } from '@mui/material';
import React, { useMemo } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { DragIndicator } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

interface LabelProps {
  name: string;
  selected?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  deleteIcon?: React.ReactElement;
  fulfilled?: boolean;
  listeners?: SyntheticListenerMap;
  attributes?: DraggableAttributes;
  variant?: string;
}

const SelectableLabel = ({
  name,
  selected,
  onClick,
  onDelete,
  fulfilled = false,
  listeners,
  attributes,
  variant
}: LabelProps) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  const icon = useMemo(() => {
    if (variant === 'add') {
      return <AddIcon />;
    }

    if (listeners && attributes) {
      return <DragIndicator {...listeners} {...attributes} />;
    }

    return fulfilled ? (
      <TaskAltIcon fontSize="small" color="action" />
    ) : (
      <RadioButtonUncheckedIcon fontSize="small" color="disabled" />
    );
  }, [listeners, attributes, fulfilled]);

  return (
    <Chip
      size="small"
      color={fulfilled ? 'success' : 'default'}
      label={name}
      onClick={handleClick}
      onDelete={onDelete && handleDelete}
      icon={icon}
      sx={{
        borderRadius: 1,
        border: 1,
        borderColor: selected ? 'info.main' : 'transparent'
      }}
    />
  );
};

export default SelectableLabel;
