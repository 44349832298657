/* eslint-disable @typescript-eslint/no-explicit-any */
import dagreD3 from 'dagre-d3';
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/system';
import styles from './flowgraph.module.css';
import { TaskDefinition, TaskType, WorkflowDefinition } from '../../types/task';
import { CallMerge as AggregateIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

export interface FlowGraphProps {
  flow: WorkflowDefinition;
  onEvent?: (event: FlowGraphEvent) => void;
}

export enum FlowGraphEventType {
  ON_CLICK = 'ON_CLICK',
  ON_DELETE = 'ON_DELETE',
  ON_ADD_AGGREGATE = 'ON_ADD_AGGREGATE'
}

export interface FlowGraphEvent {
  nodeId: string;
  type: FlowGraphEventType;
}

const FlowGraphView = ({ flow, onEvent }: FlowGraphProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showAggregateButton, setShowAggregateButton] = useState(false);
  const deleteTimeoutRef = useRef<number | null>(null);
  const theme = useTheme();

  const isDarkMode = () => theme.palette.mode === 'dark';

  const centerGraph = (svgGroup: any, graph: any) => {
    const graphWidth = (graph.graph() as any).width;
    const graphHeight = (graph.graph() as any).height;
    const svgWidth = svgRef.current.clientWidth;
    const svgHeight = svgRef.current.clientHeight;
    const xCenterOffset = (svgWidth - graphWidth) / 2;
    const yCenterOffset = (svgHeight - graphHeight) / 2;
    svgGroup.attr('transform', `translate(${xCenterOffset}, ${yCenterOffset})`);
  };

  const scaleGraphToFit = (svgGroup: any, graph: any, zoom: any, svg: any) => {
    const graphWidth = (graph.graph() as any).width;
    const graphHeight = (graph.graph() as any).height;
    const svgWidth = svgRef.current.clientWidth;
    const svgHeight = svgRef.current.clientHeight;
    const scale = Math.min(1, Math.min(svgWidth / graphWidth, svgHeight / graphHeight));
    const xCenterOffset = (svgWidth - graphWidth * scale) / 2;
    const yCenterOffset = (svgHeight - graphHeight * scale) / 2;
    const initialTransform = d3.zoomIdentity.translate(xCenterOffset, yCenterOffset).scale(scale);
    svg.call(zoom.transform, initialTransform);
  };

  const roundCorners = (node: any) => {
    node.rx = node.ry = 3;
  };

  const handleNodeClick = (nodeId: string) => {
    onEvent({ nodeId: nodeId, type: FlowGraphEventType.ON_CLICK });
  };

  const getNodeClass = (node: TaskDefinition) => {
    const type = node.type.toLowerCase().replace(/_/g, '-');
    const state = node.status.toLowerCase().replace(/_/g, '-');

    if (node.type == TaskType.NOT_YET_DEFINED) {
      return `${styles.node} ${styles[type]}`;
    }

    return `${styles.node} ${styles[type]} ${type} ${styles[state]}`;
  };

  const getNodeShape = (type: TaskType) => {
    switch (type) {
      case TaskType.TASK:
        return 'rect';
      case TaskType.GROUP:
        return 'rect';
      case TaskType.DECISION:
        return 'diamond';
      case TaskType.START:
      case TaskType.END:
        return 'circle';
      case TaskType.JOIN:
      case TaskType.NOT_YET_DEFINED:
      default:
        return 'rect';
    }
  };

  useEffect(() => {
    const nodes = flow.tasks;
    if (nodes.length == 0 || (nodes.length == 1 && nodes[0].name === 'Empty Flow')) {
      return;
    }
    const g = new dagreD3.graphlib.Graph().setGraph({ compound: true });
    g.setDefaultEdgeLabel(() => ({}));

    const darkClass = isDarkMode() ? styles.dark : '';

    nodes.forEach((node) => {
      g.setNode(node.taskId, {
        label: node.name,
        shape: getNodeShape(node.type),
        class: `${getNodeClass(node)} ${darkClass}`,
        data: { nodeId: node.taskId }
      });
    });

    nodes.forEach((node) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          g.setEdge(node.taskId, child.taskId, {
            label: child.name,
            curve: d3.curveBasis,
            style: 'fill: none',
            class: styles.edgePath,
            arrowheadClass: styles.edgeArrowhead
          });
        });
      }
    });
    nodes.forEach((node) => {
      roundCorners(g.node(node.taskId));
    });

    const render = new (dagreD3 as any).render();
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    const svgGroup = svg.append('g');

    render(svgGroup, g);

    const zoom = d3.zoom().on('zoom', (event) => {
      setShowDeleteButton(false);
      setHoveredNode(null);
      svgGroup.attr('transform', event.transform);
    });
    svg.call(zoom);

    svgGroup
      .selectAll('g.node')
      .attr('nodeId', (d: any) => d)
      .on('click', (event: PointerEvent) => {
        const clickedElement: any = event.currentTarget;
        const nodeId = clickedElement.getAttribute('nodeId');
        handleNodeClick(nodeId);
      })
      .on('mouseover', (event: PointerEvent) => {
        const hoveredElement: any = event.currentTarget;
        const nodeId = hoveredElement.getAttribute('nodeId');
        const isDeletableNode = nodes.some(
          (node) => node.taskId == nodeId && node.type != TaskType.START && node.type != TaskType.END
        );
        const isAggregatableNode = nodes.some(
          (node) =>
            node.taskId == nodeId &&
            node.type != TaskType.START &&
            node.type != TaskType.END &&
            (node.type == TaskType.LABEL_PROVIDER || node.type == TaskType.JOIN)
        );
        if (isDeletableNode || isAggregatableNode) {
          setHoveredNode(nodeId);
          setShowDeleteButton(isDeletableNode);
          setShowAggregateButton(isAggregatableNode);
          if (deleteTimeoutRef.current) {
            clearTimeout(deleteTimeoutRef.current);
          }
        }
      })
      .on('mouseout', () => {
        if (deleteTimeoutRef.current) {
          clearTimeout(deleteTimeoutRef.current);
        }
        deleteTimeoutRef.current = window.setTimeout(() => {
          setShowDeleteButton(false);
          setHoveredNode(null);
        }, 800);
      });

    centerGraph(svgGroup, g);
    scaleGraphToFit(svgGroup, g, zoom, svg);
  }, [flow, theme]);

  const getNodeCoordinates = (nodeId: string) => {
    const nodeElement = svgRef.current.querySelector(`g[nodeId="${nodeId}"]`);
    if (nodeElement) {
      const { x, y, width, height } = nodeElement.getBoundingClientRect();
      return {
        x: x + width + 10 - svgRef.current.getBoundingClientRect().x,
        y: y + height + 10 - svgRef.current.getBoundingClientRect().y
      };
    }
    return { x: 0, y: 0 };
  };

  const handleMouseEnterDeleteButton = () => {
    clearTimeout(deleteTimeoutRef.current);
  };

  const handleMouseLeaveDeleteButton = () => {
    setShowDeleteButton(false);
    setHoveredNode(null);
  };

  return (
    <div id={'svg-wrapper'} style={{ width: '100%', height: '100%' }}>
      <svg
        ref={svgRef}
        width={'100%'}
        height={'100%'}
        className={isDarkMode() ? 'flowgraphSvgDark' : 'flowgraphSvg'}
      ></svg>
      <>
        {hoveredNode && showDeleteButton && (
          <Tooltip
            title="Delete"
            onMouseEnter={handleMouseEnterDeleteButton}
            onMouseLeave={handleMouseLeaveDeleteButton}
            sx={{
              position: 'absolute',
              top: `${getNodeCoordinates(hoveredNode).y}px`,
              left: `${getNodeCoordinates(hoveredNode).x}px`,
              transform: 'translate(-50%, -50%)'
            }}
          >
            <IconButton
              size="small"
              className={styles.deleteIcon}
              onClick={(event) => {
                event.stopPropagation();
                onEvent({ nodeId: hoveredNode, type: FlowGraphEventType.ON_DELETE });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}

        {hoveredNode && showAggregateButton && (
          <Tooltip
            title="Aggregate"
            onMouseEnter={handleMouseEnterDeleteButton}
            onMouseLeave={handleMouseLeaveDeleteButton}
            sx={{
              position: 'absolute',
              top: `${getNodeCoordinates(hoveredNode).y}px`,
              left: `${getNodeCoordinates(hoveredNode).x - 24}px`,
              transform: 'translate(-50%, -50%)'
            }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                onEvent({ nodeId: hoveredNode, type: FlowGraphEventType.ON_ADD_AGGREGATE });
              }}
            >
              <AggregateIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    </div>
  );
};

export default FlowGraphView;
