import * as React from 'react';
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { CircularProgress, Input, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import IconButton from '@mui/material/IconButton';

interface EditableHeaderProps {
  title: string;
  onSave?: (title: string) => void;
  loading?: boolean;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

const EditableHeader = ({ title, onSave, loading = false, variant = 'h5' }: EditableHeaderProps) => {
  const [currentTitle, setCurrentTitle] = useState(title);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setCurrentTitle(title);
  }, [title]);

  const handleEditClick = () => {
    if (onSave) {
      setEdit(true);
    }
  };

  const handleSave = () => {
    setEdit(false);
    if (onSave && title !== currentTitle) {
      onSave(currentTitle);
    }
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {edit ? (
        <Input
          size="small"
          autoFocus
          value={currentTitle}
          disabled={loading}
          onBlur={handleSave}
          onChange={(e) => setCurrentTitle(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      ) : (
        <Typography
          sx={{ flex: 1, ...(onSave && { '&:hover': { textDecoration: 'underline' } }) }}
          variant={variant}
          onClick={handleEditClick}
        >
          {currentTitle}
        </Typography>
      )}
      {onSave && (
        <>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="default" size="small" onClick={handleEditClick} disabled={loading}>
            {loading ? <CircularProgress size="1.3rem" /> : <EditIcon />}
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default EditableHeader;
