import React from 'react';
import { TaskDefinition } from '../../../types/task';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { SectionPanel } from '../../../components/SectionPanel';
import { OutputParametersEditor } from './OutputParametersEditor';
import { InputParametersEditor } from './InputParametersEditor';
import { Mirror } from '../../../types/mirror';

interface MirrorGenericTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorGenericTaskEditor = ({ mirror, task, onClose }: MirrorGenericTaskEditorProps) => {
  return (
    <MirrorTaskEditorPanel mirror={mirror} task={task} onClose={onClose}>
      <SectionPanel title="Input">
        <InputParametersEditor workflowId={mirror.workflowId} task={task} />
      </SectionPanel>
      <SectionPanel title="Output">
        <OutputParametersEditor workflowId={mirror.workflowId} task={task} readOnly={false} />
      </SectionPanel>
    </MirrorTaskEditorPanel>
  );
};
