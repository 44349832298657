import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFormQuery } from '../../state/api';
import FormEditor from '../../components/FormEditor';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Form as FormData, FormStatus } from '../../types/form';

const Form = () => {
  const { formId } = useParams();
  const { data: formData, isLoading, isError } = useGetFormQuery(formId || '');
  const [completedForm, setCompletedForm] = useState<FormData>();

  useEffect(() => {
    if (formData && formData.status == FormStatus.COMPLETED) {
      setCompletedForm(formData);
    }
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError || !formData) return <div>Error or no data</div>;

  const onCompleted = (form: FormData) => {
    setCompletedForm(form);
  };

  return (
    <Box width="800px" m="auto" p={2}>
      <FormEditor initialFormData={formData} onCompleted={onCompleted} />
      {completedForm && (
        <>
          <Typography variant="h6" component="div">
            Form has successfully been submitted.
          </Typography>
          <Typography variant="body2" component="div">
            You can now close this tab.
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Form;
