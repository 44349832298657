import React, { useState } from 'react';
import { Box } from '@mui/system';
import { usePostMirrorMutation } from '../../state/api';
import { Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const MirrorCreate = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [createMirror, { isLoading, isError }] = usePostMirrorMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const mirror = await createMirror({
        name: name
      }).unwrap();

      if (mirror) {
        navigate(`/mirrors/${mirror.id}`);
      }
    } catch (error) {
      console.error('creating new cup failed:', error);
    }
  };

  const onCancel = () => {
    navigate('/');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography variant={'h6'}>Create Mirror</Typography>
        <TextField
          label="Mirror Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          autoFocus
        />
        {isError && <Typography color="error">Error creating mirror</Typography>}
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          <Button type="submit" variant="contained" color="primary" disabled={name.length == 0 || isLoading}>
            Create
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};
