import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type AuthState = {
  state: {
    isFetching: boolean | false;
  };
  isLoggedIn: boolean | false;
};

const slice = createSlice({
  name: 'auth',
  initialState: {} as AuthState,
  reducers: {
    setIsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    }
  }
});

export const { setIsLoggedIn, logout } = slice.actions;

export default slice.reducer;

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn != null && state.auth.isLoggedIn;
