import React from 'react';
import Box from '@mui/material/Box';
import { useGetCupQuery, useGetLabelsQuery } from '../../state/api';
import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CupStandardLabelEditor } from './CupStandardLabelEditor';
import { CupType } from '../../types/cup';
import { LabelCategory } from '../../types/label';
import { SectionPanel } from '../../components/SectionPanel';
import { CupFilterEditor } from './tabular/CupFilterEditor';
import { CupTabularDataEditor } from './tabular/CupTabularDataEditor';
import { CupTaskReference } from './CupEditor';

export interface CupEditorProps {
  cupId: string;
  taskReference: CupTaskReference;
  showTitle?: boolean;
}

const CupExtractionEditor = ({ cupId, showTitle = false }: CupEditorProps) => {
  const navigate = useNavigate();
  const { data: cup, isLoading } = useGetCupQuery(cupId);
  //  const [selectedExtraction, setSelectedExtraction] = useState<string>(null);
  //  const [selectedExtractionPolicy, setSelectedExtractionPolicy] = useState<string>(null);
  //  const [selectedFilter, setSelectedFilter] = useState<string>(null);
  //  const [exampleExtractions, setExampleExtraction] = useState<TextExtraction[]>([]);
  //const [extractions, setExtractions] = useState<Map<string, TextExtraction[]>>(new Map());
  //const [postCupTest] = usePostCupTestMutation();
  //const [triggerGetExtractionsByDocumentId] = useLazyGetExtractionsByDocumentIdQuery();
  //  const { isFetching: isDocsFetching } = useGetDocumentsQuery();
  const { data: standardLabels = [] } = useGetLabelsQuery({ category: LabelCategory.STANDARD });

  if (isLoading) {
    return;
  }

  const breadcrumbs = [
    <Link variant={'h6'} underline="hover" key="1" color="inherit" href="#" onClick={() => navigate('/cups')}>
      CUPs
    </Link>,
    <Typography variant={'h6'} key="2" color="text.primary">
      {cup.name}
    </Typography>
  ];

  /*const handleCupTest = async (documentId: string) => {
    await new Promise((resolve) => setTimeout(resolve, 4000));
    const extractionsMap: Map<string, TextExtraction[]> = new Map();
    const newExtractions: TextExtraction[] = cup.extractions.map((extraction) => {
      const label = extraction.blueprint.extractions[0].label;
      const stringValue = extractionValues.get(label);
      const textExtraction = {
        textId: documentId,
        extractionId: documentId,
        createdAt: new Date(),
        labelId: extraction.name,
        labelName: label,
        labelTitle: label,
        start: 0,
        end: 0,
        value: {
          type: ExtractionValueType.STRING,
          string: stringValue
        },
        original: stringValue,
        children: [] as TextExtraction[]
      } as TextExtraction;
      return textExtraction;
    });
*/
  //extractionsMap.set(documentId, newExtractions);
  //console.log(newExtractions);
  //setExtractions(extractionsMap);
  //setExampleExtraction(newExtractions);
  //handleOnExampleExtractions(cup.exampleDocuments[0].name, newExtractions);

  /*try {
      await postCupTest({
        cupId: cup.id,
        documentId: documentId
      }).unwrap();
    } catch (error) {
      console.error('Failed initiate cup test', error);
    }
  };*/

  /*useEffect(() => {
    if (cup && cup.extractions && cup.exampleDocuments.length > 0) {
      cup.exampleDocuments.forEach((doc) => {
        triggerGetExtractionsByDocumentId(doc.documentId).then((result) => {
          if (result.data) {
            setExtractions((prevExtractions) => {
              const updatedExtractions = new Map(prevExtractions);
              updatedExtractions.set(doc.documentId, result.data);
              return updatedExtractions;
            });
          }
        });
      });
    }
  }, [cup, isDocsFetching]);*/

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'top',
        p: 1
      }}
    >
      {showTitle && (
        <Stack spacing={2}>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      )}
      <SectionPanel title="Filter" help="The filter decide when the import is triggered to run">
        <CupFilterEditor cup={cup} />
      </SectionPanel>
      {cup && cup.type == CupType.TABULAR && (
        <SectionPanel title="Example Document" help="Select labels of columns you wish to import">
          <CupTabularDataEditor cup={cup} />
        </SectionPanel>
      )}

      {cup && cup.type == CupType.EXTRACTION && (
        <SectionPanel title="Standard Labels">
          <CupStandardLabelEditor cup={cup} labels={standardLabels} />
        </SectionPanel>
      )}
    </Box>
  );
};
export default CupExtractionEditor;
