import React from 'react';
import { useParams } from 'react-router-dom';
import CupExtractionEditor from './CupExtractionEditor';
import { MirrorContextProvider } from '../mirror/MirrorContextProvider';

export interface CupTaskReference {
  mirrorId: string;
  workflowId: string;
  taskId: string;
}

interface CupEditorProps {
  cupId?: string;
  taskReference?: CupTaskReference;
}

export default function CupEditor({ cupId: propsCupId, taskReference }: CupEditorProps) {
  const { cupId: paramsCupId } = useParams();
  const cupId = propsCupId || paramsCupId;
  const showTitle = !(propsCupId != null);

  return (
    <MirrorContextProvider workflowId={taskReference.workflowId}>
      <CupExtractionEditor cupId={cupId} showTitle={showTitle} taskReference={taskReference} />
    </MirrorContextProvider>
  );
}
