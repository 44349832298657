import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { FieldType, Form, FormOptionNames as FormOptionName, FormStatus } from '../types/form';
import { usePutFormMutation } from '../state/api';
import { ChatItem } from '../types/chat';
import { AppDispatch } from '../state/store';
import { useDispatch } from 'react-redux';
import { api } from '../state/api';

interface FormEditorProps {
  initialFormData: Form;
  chatItem?: ChatItem;
  onCompleted: (form: Form) => void;
}

const FormEditor = ({ initialFormData, chatItem, onCompleted }: FormEditorProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [formData, setFormData] = useState<Form>(initialFormData);
  const [putForm] = usePutFormMutation();
  const optionsIndex = Object.fromEntries(initialFormData.options.map((opt) => [opt.name, opt.value]));
  const handleChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      fields: formData.fields.map((field) => (field.name === name ? { ...field, value: value } : field))
    });
  };

  const invalidateChatItems = () => {
    if (chatItem) {
      dispatch(api.util.invalidateTags([{ type: 'Chat', id: chatItem.message.chatId }]));
    }
  };

  const setCompleted = () => {
    setFormData({
      ...formData,
      status: FormStatus.COMPLETED
    });
    onCompleted(formData);
  };

  const handleSave = async () => {
    try {
      await putForm(formData).unwrap();
      setCompleted();
      invalidateChatItems();
    } catch (error) {
      console.error('Failed to save form data', error);
    }
  };

  const handleReject = async () => {
    try {
      const rejectedFormData = {
        ...formData,
        fields: [
          ...formData.fields,
          {
            name: 'formRejected',
            value: 'true',
            type: FieldType.BOOLEAN,
            description: 'The form was rejected.',
            required: false
          }
        ]
      };
      await putForm(rejectedFormData).unwrap();
      setCompleted();
      invalidateChatItems();
    } catch (error) {
      console.error('Failed to save form data', error);
    }
  };

  const formOptionValue = (name: FormOptionName, defaultValue: string) => {
    const value = optionsIndex[name];
    return value !== undefined && value !== '' ? value : defaultValue;
  };

  const formOptionExists = (name: FormOptionName) => {
    return name in optionsIndex;
  };

  const FormButtons = () => {
    if (formOptionExists(FormOptionName.FORM_REJECT_LABEL)) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <Button variant="contained" onClick={handleSave}>
            {formOptionValue(FormOptionName.FORM_SUBMIT_LABEL, 'Submit')}
          </Button>
          <Button variant="contained" onClick={handleReject}>
            {formOptionValue(FormOptionName.FORM_REJECT_LABEL, 'Reject')}
          </Button>
        </Box>
      );
    } else {
      return (
        <Button variant="contained" onClick={handleSave}>
          {formOptionValue(FormOptionName.FORM_SUBMIT_LABEL, 'Submit')}
        </Button>
      );
    }
  };

  return (
    <>
      {formData.status == FormStatus.CREATED ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: '48rem' }}>
          <Typography variant="h6" component="div">
            {formData.title}
          </Typography>
          <Typography variant="body2" component="div">
            {formData.description}
          </Typography>
          {formData.fields.map((field, index) => (
            <TextField
              key={index}
              label={field.description}
              variant="outlined"
              required={field.required}
              value={field.value || ''}
              onChange={(e) => handleChange(field.name, e.target.value)}
              type={field.type === FieldType.NUMBER ? 'number' : 'text'}
            />
          ))}
          <FormButtons />
        </Box>
      ) : formData.status == FormStatus.COMPLETED ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}></Box>
      ) : null}
    </>
  );
};

export default FormEditor;
