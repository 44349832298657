import React from 'react';
import { ChatAction, ChatMessage } from '../../types/chat';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ChatActionViewProps {
  message: ChatMessage;
  action: ChatAction;
}

const ChatActionView = ({ action }: ChatActionViewProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ m: 'auto', display: 'flex', flexDirection: 'column', gap: 1 }}>
      Actions not yet implemented
      {action && (
        <>
          <Typography variant="body1">{action.text}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, ml: 0, pl: 0 }}>
            {action.buttons.map((button, index) => (
              <Button sx={{ ml: 0 }} key={index} variant="outlined" onClick={() => navigate(button.url)}>
                {button.name}
              </Button>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ChatActionView;
