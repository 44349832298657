import { createAsyncThunk } from '@reduxjs/toolkit';
import * as chat from '../types/chat';
import { api } from './api';
import { v4 as uuidv4 } from 'uuid';

// *** File upload
export interface ChatUploadFilesArgs {
  chatId: string;
  chatMessage?: chat.ChatMessage;
  message: string;
  files: FileList;
}

const createUploadChatItem = (args: ChatUploadFilesArgs) => {
  const filesArray = Array.from(args.files);

  const chatMessage: chat.ChatMessage = args.chatMessage
    ? args.chatMessage
    : {
        id: uuidv4(),
        chatId: args.chatId,
        type: chat.ChatMessageType.UPLOAD,
        text: args.message,
        dataReferenceId: '-',
        createdBy: '-',
        createdAt: new Date().toISOString()
      };

  const chatUpload: chat.ChatUpload = {
    processId: '-',
    files: [],
    uiState: {
      files: filesArray.map((file: File) => file.name),
      status: chat.ChatActionStatus.RUNNING,
      message: args.message
    }
  };

  const chatItem: chat.ChatItem = {
    message: chatMessage,
    threadItems: [],
    data: chatUpload
  };

  return chatItem;
};

export const chatUploadFilesThunk = createAsyncThunk(
  'chats/uploadFiles',
  async (args: ChatUploadFilesArgs, { dispatch, rejectWithValue }) => {
    const filesArray = Array.from(args.files);

    const chatItem = createUploadChatItem(args);
    const chatMessage = chatItem.message;
    const chatUpload = chatItem.data as chat.ChatUpload;

    try {
      if (!args.chatMessage) {
        dispatch(
          api.util.updateQueryData('getChatItems', args.chatId, (chatItems) => {
            chatItems.push(chatItem);
          })
        );
      }
      const createUploadRequest = {
        name: args.message,
        fileNames: filesArray.map((file) => file.name)
      };

      const uploadData = await dispatch(api.endpoints.postUploadFiles.initiate(createUploadRequest)).unwrap();

      if (!args.chatMessage) {
        const createChatMessage = {
          chatId: args.chatId,
          type: chat.ChatMessageType.UPLOAD,
          text: args.message,
          dataReferenceId: uploadData.id
        };

        await dispatch(api.endpoints.postChatMessage.initiate(createChatMessage)).unwrap();
      }
      dispatch(
        api.util.updateQueryData('getChatItems', args.chatId, (chatItems) => {
          const updateChatItem = (item: chat.ChatItem): chat.ChatItem => {
            if (item.message.id === chatMessage.id) {
              return {
                ...item,
                data: { ...chatUpload, processId: uploadData.id }
              };
            }
            return item;
          };

          return chatItems.map((chatItem) => {
            const updatedChatItem = updateChatItem(chatItem);

            if (updatedChatItem.threadItems && Array.isArray(updatedChatItem.threadItems)) {
              return {
                ...updatedChatItem,
                threadItems: updatedChatItem.threadItems.map(updateChatItem)
              };
            }

            return updatedChatItem;
          });
        })
      );
      const formData = new FormData();
      formData.append('processId', uploadData.id);
      filesArray.forEach((file) => {
        formData.append('file', file);
      });

      await dispatch(api.endpoints.putUploadFiles.initiate(formData)).unwrap();

      if (args.chatMessage) {
        const updateChatMessage = {
          chatId: args.chatMessage.chatId,
          chatMessageId: args.chatMessage.id,
          dataReferenceId: uploadData.id
        };
        await dispatch(api.endpoints.putChatMessage.initiate(updateChatMessage)).unwrap();
      }

      dispatch(api.util.invalidateTags([{ type: 'Chat', id: args.chatId }]));
    } catch (error) {
      dispatch(api.util.invalidateTags([{ type: 'Chat', id: args.chatId }]));
      return rejectWithValue(error.message);
    }
  }
);

// *** Creat Text

export interface ChatCreateTextArgs {
  chatId: string;
  text: string;
}

const createChatTextItem = (args: ChatCreateTextArgs) => {
  const chatMessage: chat.ChatMessage = {
    id: uuidv4(),
    chatId: args.chatId,
    type: chat.ChatMessageType.TEXT,
    text: args.text,
    dataReferenceId: '-',
    createdBy: '-',
    createdAt: new Date().toISOString()
  };

  const chatItem: chat.ChatItem = {
    message: chatMessage,
    threadItems: []
  };

  return chatItem;
};

export const chatCreateTextThunk = createAsyncThunk(
  'chats/createText',
  async (args: ChatCreateTextArgs, { dispatch, rejectWithValue }) => {
    const chatItem = createChatTextItem(args);

    try {
      dispatch(
        api.util.updateQueryData('getChatItems', args.chatId, (chatItems) => {
          chatItems.push(chatItem);
        })
      );

      await dispatch(api.endpoints.postChatText.initiate({ chatId: args.chatId, text: args.text })).unwrap();

      dispatch(api.util.invalidateTags([{ type: 'Chat', id: args.chatId }]));
    } catch (error) {
      dispatch(api.util.invalidateTags([{ type: 'Chat', id: args.chatId }]));
      return rejectWithValue(error.message);
    }
  }
);
