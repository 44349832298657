import { Address, Location } from '../../../state/model';
import { DocumentDetails } from '../../../types/document';

interface ContractingPartyCardData {
  extractionIdId: string;
  name: string;
  location: Location;
  address: Address;
}

interface ContractingPartyMarker {
  documentId: string;
  extractionId: string;
  location: Location;
}

function convert(doc: DocumentDetails): ContractingPartyMarker[] {
  return doc.parties.map((party) => {
    return {
      documentId: doc.id,
      extractionId: party.extractionId,
      location: party.location
    };
  });
}

export { ContractingPartyCardData, ContractingPartyMarker, convert };
