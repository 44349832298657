import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TaskAggregateConfig, TaskDefinition, TaskParameter } from '../../../types/task';
import { Mirror } from '../../../types/mirror';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export interface DeleteKeyEvent {
  taskId: string;
  labelId: string;
}

export interface AddKeyEvent {
  taskId: string;
  labelId: string;
}

interface MirrorAggregateTaskKeyEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  config: TaskAggregateConfig;
  onDelete?: (event: DeleteKeyEvent) => void;
  onAdd?: (event: AddKeyEvent) => void;
  onClose: () => void;
}

export function MirrorAggregateTaskKeyEditor({
  mirror,
  task,
  config,
  onDelete,
  onAdd
}: MirrorAggregateTaskKeyEditorProps) {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<TaskParameter>(null);
  const [availableKeys, setAvailableKeys] = useState<TaskParameter[]>([]);
  const [keys, setKeys] = useState<TaskParameter[]>([]);

  useEffect(() => {
    const keyParameters = config.keys.map((key) => task.input.find((input) => key == input.labelId));
    setKeys(keyParameters);
    setAvailableKeys(task.input.filter((input) => !config.keys.some((key) => key == input.labelId)));
  }, [mirror, config.keys]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onAdd) {
      onAdd({
        taskId: task.taskId,
        labelId: selectedKey.labelId
      });
    }
    setSelectedKey(null);
    setIsFormOpen(false);
  };

  const handleAddKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const key = availableKeys.find((output) => output.labelId == value);
    setSelectedKey(key);
  };

  const handleAddKeyCancel = () => {
    setSelectedKey(null);
    setIsFormOpen(false);
  };

  const handleDeleteKey = async (event: DeleteKeyEvent) => {
    if (onDelete) {
      onDelete(event);
      setIsFormOpen(false);
    }
  };

  return (
    <Box>
      {isFormOpen && (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              m: 2
            }}
          >
            <Typography variant={'h6'}>Add Aggregate Key</Typography>
            <FormControl variant="outlined">
              <InputLabel id="availabelKeysLabel">Key</InputLabel>
              <Select
                labelId="availabelKeysLabel"
                name="inputParameter"
                value={selectedKey ? selectedKey.labelId : ''}
                label="Labels"
                onChange={handleAddKeyChange}
              >
                {availableKeys.map((output, index) => (
                  <MenuItem key={index} value={output.labelId}>
                    {output.name}
                  </MenuItem>
                ))}
                ;
              </Select>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              <Button type="submit" variant="contained" color="primary" disabled={!selectedKey}>
                Add
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleAddKeyCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      )}
      {!isFormOpen && (
        <TableContainer sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow component="th" scope="row">
                <TableCell>Label</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" edge="start" onClick={() => setIsFormOpen(true)} size="medium">
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((key) => (
                <TableRow
                  key={key.parameterId}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover .deleteIcon': {
                      opacity: 1
                    }
                  }}
                >
                  <TableCell>{key.description.title}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDeleteKey({ taskId: task.taskId, labelId: key.labelId })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
