import { IconButton } from '@mui/material';
import React from 'react';
// import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlineBlank';
import Tooltip from '@mui/material/Tooltip';

export type TrafficLightStatus = 'RED' | 'AMBER' | 'GREEN';

interface StatusDotProps {
  status: TrafficLightStatus;
  tooltip?: React.ReactNode;
  onClick?: () => void;
}

const StatusDot = ({ status, tooltip, onClick }: StatusDotProps) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const getIcon = (status: TrafficLightStatus) => {
    if (typeof status === 'undefined') {
      return <CheckBoxOutlined />;
    }
    return <SquareIcon />;
  };

  const getColor = (status: TrafficLightStatus) => {
    if (status === 'RED') {
      return 'error';
    } else if (status === 'AMBER') {
      return 'warning';
    } else if (status === 'GREEN') {
      return 'success';
    } else if (status === null) {
      return 'default';
    }

    return 'inherit';
  };

  const color = getColor(status);
  const icon = getIcon(status);

  const button = (
    <IconButton size="small" color={color} onClick={handleOnClick}>
      {icon}
    </IconButton>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="top">
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default StatusDot;
