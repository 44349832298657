import React, { useState } from 'react';
import { useDeleteWorkflowInstanceByIdMutation, useGetMirrorsQuery, useGetWorkflowsQuery } from '../../state/api';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import SectionBody from '../../components/SectionBody';
import ProgressCircle from '../../components/ProgressCircle';
import HelpText from '../../components/HelpText';
import SectionTable from '../../components/SectionTable';
import CreateButton from '../../components/CreateButton';

export default function Workflows() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: mirrors = [], isLoading: isMirrorsLoading } = useGetMirrorsQuery();
  const { data: workflows = [], isLoading: isWorkflowsLoading } = useGetWorkflowsQuery();
  const [deleteWorkflow] = useDeleteWorkflowInstanceByIdMutation();
  const [deleteWorkflowId, setDeleteWorkflowId] = useState<string>('');
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const handleClickCreateFlow = () => {
    navigate('/workflows/create');
  };

  const handleDeleteShowDialog = (event, id: string) => {
    event.stopPropagation();
    setDeleteWorkflowId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteWorkflowId('');
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteWorkflow = async () => {
    try {
      await deleteWorkflow(deleteWorkflowId).unwrap();
      setDeleteMessage(`Workflow deleted`);
      setDeleteWorkflowId('');
    } catch (error) {
      console.error('Failed to delete workflow: ', error);
    }
  };

  const renderBody = () => {
    return (
      <>
        {mirrors.length === 0 && <HelpText text="You need to create a mirror before you can create workflows" />}

        {mirrors.length > 0 && workflows.length === 0 && <Alert severity="info">There are no workflows yet.</Alert>}

        {workflows.length > 0 && (
          <SectionTable>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows.map((workflow) => (
                <TableRow
                  key={workflow.workflowId}
                  hover
                  onClick={() => navigate(`/workflows/${workflow.workflowInstanceId}`)}
                >
                  <TableCell>{workflow.name}</TableCell>
                  <TableCell>{workflow.status}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => handleDeleteShowDialog(e, workflow.workflowInstanceId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </SectionTable>
        )}
      </>
    );
  };

  return (
    <>
      <SectionHeader title="Workflows">
        <CreateButton title="Create Workflow" onClick={handleClickCreateFlow} disabled={mirrors.length === 0} />
      </SectionHeader>

      <SectionBody>{isMirrorsLoading || isWorkflowsLoading ? <ProgressCircle /> : renderBody()}</SectionBody>

      <Dialog fullScreen={fullScreen} open={deleteWorkflowId.length > 0} onClose={handleDeleteCancel}>
        <DialogTitle>{'Delete workflow'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this workflow?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={() => handleDeleteWorkflow()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={6000}
        message={deleteMessage}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
}
