import React, { useState } from 'react';
import { Mirror } from '../../../types/mirror';
import { TaskAggregateConfig, TaskDefinition } from '../../../types/task';
import { MirrorTaskEditorPanel } from './MirrorTaskEditorPanel';
import { SectionPanel } from '../../../components/SectionPanel';
import { AddKeyEvent, DeleteKeyEvent, MirrorAggregateTaskKeyEditor } from './MirrorAggregateTaskKeyEditor';
import { useGetAggregateTaskConfigQuery, usePutAggregateTaskConfigMutation } from '../../../state/api';
import {
  AddFunctionEvent,
  DeleteFunctionEvent,
  MirrorAggregateTaskFunctionEditor
} from './MirrorAggregateTaskFunctionEditor';

interface MirrorAggregateTaskEditorProps {
  mirror: Mirror;
  task: TaskDefinition;
  onClose: () => void;
}

export const MirrorAggregateTaskEditor = ({ mirror, task, onClose }: MirrorAggregateTaskEditorProps) => {
  const [putTaskAggregateConfig] = usePutAggregateTaskConfigMutation();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: aggregateConfig, isLoading } = useGetAggregateTaskConfigQuery({
    workflowId: mirror.workflowId,
    taskId: task.taskId
  });

  const updateConfig = async (updatedConfig: TaskAggregateConfig) => {
    try {
      await putTaskAggregateConfig({
        workflowId: mirror.workflowId,
        taskId: task.taskId,
        config: updatedConfig
      }).unwrap();
    } catch (error) {
      if (error.status) {
        setErrorMessage('Updating Aggregate Config Failed.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  const handleOnAddKey = (event: AddKeyEvent) => {
    const updatedConfig = {
      ...aggregateConfig,
      keys: Array.from(new Set([event.labelId, ...aggregateConfig.keys]))
    };
    updateConfig(updatedConfig);
  };

  const handleOnDeleteKey = (event: DeleteKeyEvent) => {
    const updatedConfig = {
      ...aggregateConfig,
      keys: aggregateConfig.keys.filter((key) => key != event.labelId)
    };
    updateConfig(updatedConfig);
  };

  const handleOnAddFunction = (event: AddFunctionEvent) => {
    const functionExists = aggregateConfig.functions.find((fun) => fun.labelId == event.aggregateFunction.labelId);
    if (!functionExists) {
      const updatedConfig = {
        ...aggregateConfig,
        functions: [event.aggregateFunction, ...aggregateConfig.functions]
      };
      updateConfig(updatedConfig);
    } else {
      setErrorMessage('Aggregate function for selected label already exixts.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const handleOnDeleteFunction = (event: DeleteFunctionEvent) => {
    const updatedConfig = {
      ...aggregateConfig,
      functions: aggregateConfig.functions.filter((fun) => fun.labelId != event.labelId)
    };
    updateConfig(updatedConfig);
  };

  return (
    <MirrorTaskEditorPanel mirror={mirror} task={task} onClose={onClose}>
      <SectionPanel title="Keys">
        {!isLoading && (
          <MirrorAggregateTaskKeyEditor
            mirror={mirror}
            task={task}
            config={aggregateConfig}
            onAdd={handleOnAddKey}
            onDelete={handleOnDeleteKey}
            onClose={onClose}
          />
        )}
      </SectionPanel>
      <SectionPanel title="Functions">
        {!isLoading && (
          <MirrorAggregateTaskFunctionEditor
            mirror={mirror}
            task={task}
            config={aggregateConfig}
            onAdd={handleOnAddFunction}
            onDelete={handleOnDeleteFunction}
            onClose={onClose}
          />
        )}
      </SectionPanel>
      {errorMessage}
    </MirrorTaskEditorPanel>
  );
};
