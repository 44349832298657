import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { logout, setIsLoggedIn } from './authSlice';
import { api } from './api';

export const authStateListener = createListenerMiddleware();

authStateListener.startListening({
  matcher: isAnyOf(setIsLoggedIn, logout),
  effect: (action, listenerApi) => {
    listenerApi.dispatch(api.util.invalidateTags(['Chat', 'User', 'Document', 'Processes']));
  }
});
