import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useDeleteMirrorByIdMutation, useGetMirrorsQuery, usePostMirrorMutation } from '../../state/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/system';
import SectionTable from '../../components/SectionTable';
import SectionBody from '../../components/SectionBody';
import HelpText from '../../components/HelpText';
import ProgressCircle from '../../components/ProgressCircle';
import CreateButton from '../../components/CreateButton';

export default function Mirrors() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: mirrors = [], isLoading: isMirrorsLoading } = useGetMirrorsQuery();
  const [createMirror, { isLoading: createMirrorLoading }] = usePostMirrorMutation();
  const [deleteMirror] = useDeleteMirrorByIdMutation();
  const [deleteMirrorId, setDeleteMirrorId] = useState<string>('');
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const handleClickAddMirror = async () => {
    try {
      const mirror = await createMirror({
        name: 'New Mirror'
      }).unwrap();

      if (mirror) {
        navigate(`/mirrors/${mirror.id}`);
      }
    } catch (error) {
      setDeleteMessage(`creating new mirror failed`);
    }
  };

  const handleShowDeleteDialog = (event, id: string) => {
    event.stopPropagation();
    setDeleteMirrorId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteMirrorId('');
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteMirror = async () => {
    try {
      await deleteMirror(deleteMirrorId).unwrap();
      setDeleteMessage(`Mirror deleted`);
      setDeleteMirrorId('');
    } catch (error) {
      console.error('Failed to delete mirror: ', error);
    }
  };

  const renderBody = () => {
    return (
      <>
        {mirrors.length === 0 && <HelpText text="There are no mirrors yet, why not create one?" />}
        {mirrors.length > 0 && (
          <SectionTable>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mirrors.map((mirror) => (
                <TableRow key={mirror.id} hover onClick={() => navigate(`/mirrors/${mirror.id}`)}>
                  <TableCell>{mirror.name}</TableCell>
                  <TableCell>{mirror.status}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                      <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, mirror.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </SectionTable>
        )}
      </>
    );
  };

  return (
    <>
      <SectionHeader title="Mirrors">
        <CreateButton title="Create Mirror" onClick={handleClickAddMirror} disabled={createMirrorLoading} />
      </SectionHeader>
      <SectionBody>{isMirrorsLoading ? <ProgressCircle /> : renderBody()}</SectionBody>

      <Dialog fullScreen={fullScreen} open={deleteMirrorId.length > 0} onClose={handleDeleteCancel}>
        <DialogTitle id="responsive-dialog-title">{'Delete mirror'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to delete this mirror?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={() => handleDeleteMirror()} color={'error'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={6000}
        message={deleteMessage}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
}
