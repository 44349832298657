interface CreateUploadRequest {
  name: string;
  fileNames: string[];
}

interface CreateUploadResponse {
  processId: string;
}

enum UploadReferenceType {
  CUP = 'CUP',
  WORKFLOW = 'WORKFLOW'
}

interface UploadReference {
  referenceId: string;
  referenceType: UploadReferenceType;
  documentSetId?: string;
}

export { CreateUploadRequest, CreateUploadResponse, UploadReferenceType, UploadReference };
