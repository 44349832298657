import React, { useState } from 'react';
import Chat from '../../components/chat/Chat';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import { useGetDemoStateQuery, usePutDemoStateMutation } from '../../state/api';
import { DemoState } from '../../types/demo';

const AdminChat = () => {
  const [chatId, setChatId] = useState<string>('');
  const { data: state = { state: [] } } = useGetDemoStateQuery();
  const [updateState] = usePutDemoStateMutation();
  const [updated, setUpdated] = useState<DemoState>({ state: [] });

  const handleChange = (name: string, value: string) => {
    setUpdated((cur) => {
      return {
        state: [...cur.state.filter((s) => s.name !== name), { name, value }]
      };
    });
  };

  const getUpdatedValue = (name: string) => {
    const update = updated.state.find((s) => s.name === name);
    if (update) {
      return update.value;
    }
    return undefined;
  };

  const handleSave = async (name: string) => {
    const updatedValue = getUpdatedValue(name);

    setUpdated((cur) => {
      return {
        state: cur.state.filter((s) => s.name !== name)
      };
    });

    try {
      await updateState({
        name: name,
        value: updatedValue
      }).unwrap();
    } catch (error) {
      console.error('Failed to save demo state', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Box>
        <Typography variant="h6">ChatId</Typography>
        <TextField variant="outlined" value={chatId} onChange={(e) => setChatId(e.target.value)} />
      </Box>
      <Box>
        <Typography variant="h6">State</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {state.state.map(({ name, value }) => {
            const update = getUpdatedValue(name);
            return (
              <Box key={name}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={name}
                  value={update ? update : value}
                  onChange={(e) => handleChange(name, e.target.value)}
                />
                <Button variant="outlined" disabled={!update} onClick={() => handleSave(name)}>
                  Save
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>{chatId === '' ? 'Enter Chat Id' : <Chat chatId={chatId} />}</Box>
    </Box>
  );
};

export default AdminChat;
