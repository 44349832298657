import {
  ParameterId,
  TaskDefinition,
  TaskDefinitionInputParameter,
  TaskParameterDefinition,
  TaskType,
  WorkflowDefinition
} from '../task';

export const getAvailableOutputs = (workflow?: WorkflowDefinition): TaskParameterDefinition[] => {
  if (workflow) {
    return getAvailableOutputsByTask(workflow.tasks);
  }

  return [];
};

export const getAvailableOutputsByTask = (
  tasks: TaskDefinition[],
  taskFilter: (task: TaskDefinition) => boolean = () => true
): TaskParameterDefinition[] => {
  const map: Map<string, string> = new Map();
  tasks.filter(taskFilter).forEach((task) => task.output.forEach((output) => map.set(output.parameterId, task.name)));

  const outputs = tasks
    .filter((t) => t.type != TaskType.AGGREGATE && t.type !== TaskType.JOIN && t.type !== TaskType.EXPORT)
    .filter(taskFilter)
    .flatMap((task) => task.output);

  return mapUniqueNames(map, outputs);
};

const mapUniqueNames = (
  mappings: Map<ParameterId, string>,
  parameters: TaskParameterDefinition[]
): TaskParameterDefinition[] => {
  const mappedByName = Object.groupBy(parameters, (o) => o.name);
  const groupedOutputs = Object.values(mappedByName).map((group) => {
    if (group.length > 1) {
      return group.map((output) => {
        const parameterId = output.parameterId;
        const name = output.name;
        const taskName = mappings.get(parameterId);

        if (taskName) {
          return {
            ...output,
            name: name + ` [${taskName}]`
          };
        } else {
          return {
            ...output,
            name: name
          };
        }
      });
    }
    return group;
  });
  return groupedOutputs.flat();
};

export const getInputParameter = (
  task: TaskDefinition,
  parameterId: ParameterId
): TaskDefinitionInputParameter | undefined => {
  return task.input.find((i) => i.parameterId === parameterId);
};
