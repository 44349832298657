import {
  isBooleanAnnotation,
  isDateAnnotation,
  isDurationAnnotation,
  isStringAnnotation,
  TextExtraction
} from '../annotation';

export const normalisedExtractionValue = (extraction: TextExtraction): string => {
  if (extraction) {
    if (isStringAnnotation(extraction)) {
      return extraction.value.string;
    } else if (isDateAnnotation(extraction)) {
      return extraction.value.date ? extraction.value.date.toString() : '';
    } else if (isBooleanAnnotation(extraction)) {
      return extraction.value.boolean ? 'Yes' : 'No';
    } else if (isDurationAnnotation(extraction)) {
      return extraction.value.duration ? durationToHumanReadable(extraction.value.duration) : '';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const findFirstTextExtractionByLabelName = (root: TextExtraction, labelName: string): TextExtraction | null => {
  function search(node: TextExtraction): TextExtraction | null {
    if (node.labelName === labelName && node.value) {
      return node;
    }
    for (const child of node.children) {
      const result = search(child);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  return search(root);
};

export const durationToHumanReadable = (duration: string): string => {
  const hoursMatch = duration.match(/(\d+)H/);
  const secondsMatch = duration.match(/(\d+)S/);

  if (secondsMatch && parseInt(secondsMatch[1], 10) === 0 && !hoursMatch) {
    return '';
  }

  const totalHours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;

  let result = '';

  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}`;
  }

  if (hours > 0) {
    if (days > 0) {
      result += ' and ';
    }
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  return result;
};
