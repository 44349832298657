import React from 'react';
import { Table, TableContainer } from '@mui/material';

interface SectionTableProps {
  children?: React.JSX.Element | React.JSX.Element[];
}

export default function SectionTable({ children }: SectionTableProps) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small">
        {children}
      </Table>
    </TableContainer>
  );
}
