import React, { useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useGetChatItemsQuery } from '../../state/api';

interface ChatMainProps {
  chatId: string;
}

const ChatMain = ({ chatId }: ChatMainProps) => {
  const theme = useTheme();
  const scrollToEndRef = useRef(null);

  const { data: chatItems = [], isLoading: isChatLoading } = useGetChatItemsQuery(chatId);
  const scrollToBottom = () => {
    scrollToEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatItems]);

  if (isChatLoading) {
    return <CircularProgress />;
  }

  if (chatItems.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          gap: theme.spacing(0)
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      {chatItems.map((item, index) => (
        <ChatMessage
          key={`${index}`}
          item={item}
          isLastChatMessage={chatItems.length > 0 && index == chatItems.length - 1}
        />
      ))}
      <Box
        ref={scrollToEndRef}
        sx={{
          height: theme.spacing(4)
        }}
      />
    </Box>
  );
};

export default ChatMain;
