import { Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

interface LinkProps {
  to: string;
  children: React.ReactNode;
}

const Link = ({ to, children }: LinkProps) => {
  return (
    <MuiLink component={RouterLink} to={to}>
      {children}
    </MuiLink>
  );
};

export default Link;
