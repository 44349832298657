import { DocumentBasic } from './document';
import { Label } from './label';

enum CupType {
  EXTRACTION = 'EXTRACTION',
  TABULAR = 'TABULAR'
}

enum CupStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

enum CupTabularDataColumnType {
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

enum CupFilterType {
  LABEL_FILTER = 'LABEL_FILTER',
  FILENAME_FILTER = 'FILENAME_FILTER'
}

interface CupInteraction {
  message: string;
  processed: boolean;
}
interface CupExtracionPolicy {
  label: string;
  prompt: string;
  annotations: string[];
  keywords: string[];
  regexFilter?: string;
}

interface CupRecipeBlueprint {
  id: string;
  extractions: CupExtracionPolicy[];
  interactions: CupInteraction[];
}

interface CupExtraction {
  name: string;
  blueprint: CupRecipeBlueprint;
}

interface CupFilterConfig {
  filterId: string;
  cupId: string;
  name: string;
  filterType: CupFilterType;
  parameters: Record<string, string>;
  createdAt?: string;
}

interface CupTabularDataColumn {
  index: number;
  label?: Label;
  type: CupTabularDataColumnType;
  originalName: string;
}

interface CupTabulatData {
  id: string;
  cupId: string;
  columns: CupTabularDataColumn[];
  createdAt?: string;
}

interface Cup {
  id: string;
  name: string;
  type: CupType;
  status: CupStatus;
  version: number;
  chatId: string;
  exampleDocuments: DocumentBasic[];
  extractions: CupExtraction[];
  filters: CupFilterConfig[];
  labels: string[];
  tabularData?: CupTabulatData;
}

interface CupTabularDataCell {
  rowNumber: number;
  value?: string;
}

interface CupTabularDataRow {
  rowNumber: number;
  cells: CupTabularDataCell[];
}

interface CupTabularExampleData {
  id: string;
  rows: CupTabularDataRow[];
}

interface CupCreate {
  name: string;
  type: CupType;
}

interface CupCreateComplete {
  name: string;
  extractionGroup: string;
  parameters: string[];
}

interface CupTest {
  cupId: string;
  documentId: string;
}

interface CupCreateExtraction {
  cupId: string;
  name: string;
  instruction: string;
}

interface CupUpdateExtraction {
  cupId: string;
  name: string;
  instruction: string;
}

interface CupSaveFilter {
  filterId: string;
  cupId: string;
  name: string;
  filterType: CupFilterType;
  parameters: Record<string, string>;
  createdAt?: string;
}

interface CupDeleteFilter {
  filterId: string;
  cupId: string;
}

interface CupUpdateTabularDataColumn {
  cupId: string;
  tabularDataId: string;
  index: number;
  labelId?: string;
}

interface CupSaveLabels {
  cupId: string;
  labels: string[];
}

interface CupGetTabularExampleData {
  id: string;
  cupId: string;
}

interface CupLabelParameter {
  name: string;
  description: string;
  type: string;
}

export {
  Cup,
  CupCreate,
  CupCreateComplete,
  CupCreateExtraction,
  CupGetTabularExampleData,
  CupSaveFilter,
  CupDeleteFilter,
  CupExtracionPolicy,
  CupExtraction,
  CupFilterConfig,
  CupFilterType,
  CupInteraction,
  CupLabelParameter,
  CupRecipeBlueprint,
  CupSaveLabels,
  CupStatus,
  CupUpdateExtraction,
  CupUpdateTabularDataColumn,
  CupTabulatData,
  CupTabularDataCell,
  CupTabularDataColumn,
  CupTabularDataColumnType,
  CupTabularDataRow,
  CupTabularExampleData,
  CupTest,
  CupType
};
