import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { InputParametersEditor } from '../InputParametersEditor';
import { LogicOutputParameterStep } from './LogicOutputParameterStep';
import { LogicExpressionStep } from './LogicExpressionStep';
import React, { useEffect, useState } from 'react';
import { TaskDefinition, TaskLogicExpression, WorkflowId } from '../../../../types/task';
import { useCreateTaskOutputParameterMutation } from '../../../../state/api';
import { LabelAlias } from '../../../../types/label';

interface LogicExpessionProps {
  workflowId: WorkflowId;
  task: TaskDefinition;
  expression: TaskLogicExpression;
  onClose: () => void;
  onSave: (update: TaskLogicExpression) => void;
}

const LogicExpressionEditor = ({ workflowId, task, expression, onClose, onSave }: LogicExpessionProps) => {
  const [createOutputParameter] = useCreateTaskOutputParameterMutation();
  const [update, setUpdate] = useState<TaskLogicExpression>(expression);

  useEffect(() => {
    setUpdate(expression);
  }, [expression]);

  let activeStep = 0;
  if (task.input.length > 0) {
    activeStep++;
  }
  if (task.output.length > 0) {
    activeStep++;
  }

  const handleSave = async (save: TaskLogicExpression) => {
    onSave(save);
  };

  const handleCreateOutputParameter = async (name: string) => {
    const result = await createOutputParameter({
      workflowId: workflowId,
      taskId: task.taskId,
      labelName: name
    }).unwrap();
    handleSave({
      ...update,
      mappedTo: result.parameterId
    });
  };

  const handleChangeOutputParameter = async (parameter: LabelAlias) => {
    const result = await createOutputParameter({
      workflowId: workflowId,
      taskId: task.taskId,
      labelName: parameter.name,
      labelId: parameter.labelId
    }).unwrap();
    handleSave({
      ...update,
      mappedTo: result.parameterId
    });
  };

  const handleCancel = () => {
    setUpdate(expression);
    onClose();
  };

  return (
    <Stepper orientation="vertical" activeStep={activeStep}>
      <Step expanded>
        <StepLabel>Choose the labels you wish to apply logic to</StepLabel>
        <StepContent>
          <InputParametersEditor workflowId={workflowId} task={task} edit={activeStep < 2} />
        </StepContent>
      </Step>
      <Step expanded={activeStep > 0}>
        <StepLabel>Select output</StepLabel>
        <StepContent>
          <LogicOutputParameterStep
            task={task}
            expression={update}
            onChange={handleChangeOutputParameter}
            onCreate={handleCreateOutputParameter}
          />
        </StepContent>
      </Step>
      <Step expanded={activeStep > 1}>
        <StepLabel>Describe the logic</StepLabel>
        <StepContent>
          <LogicExpressionStep expression={update} onSave={handleSave} onCancel={handleCancel} />
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default LogicExpressionEditor;
