import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { StandardLabel, TextExtraction } from '../../types/annotation';
import SectionTable from '../../components/SectionTable';
import { findFirstTextExtractionByLabelName, normalisedExtractionValue } from '../../types/utils/extractions';

interface DocumentExtractionListProps {
  extractions: TextExtraction[];
}

const DocumentExtractionList = ({ extractions }: DocumentExtractionListProps) => {
  const [filteredExtractions, setFilteredExtractions] = useState<TextExtraction[]>([]);

  const textExtractionValue = (extraction: TextExtraction): string => {
    if (extraction.labelName == StandardLabel.ContractingParty) {
      return normalisedExtractionValue(findFirstTextExtractionByLabelName(extraction, 'PartyName'));
    } else if (extraction.labelName == StandardLabel.AutoRenewal) {
      return normalisedExtractionValue(extraction);
    } else {
      return normalisedExtractionValue(extraction);
    }
  };

  useEffect(() => {
    setFilteredExtractions(extractions.filter((extraction) => extraction.labelName !== 'Kpi'));
  }, [extractions]);

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>Label</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredExtractions.map((extraction, index) => (
          <TableRow key={extraction.labelId + index} hover>
            <TableCell>{extraction.labelTitle}</TableCell>
            <TableCell>{textExtractionValue(extraction)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SectionTable>
  );
};

export default DocumentExtractionList;
