import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Cup, CupTabularDataColumn } from '../../../types/cup';
import {
  useGetCupTabularExampleDataQuery,
  usePostLabelMutation,
  usePutCupTabularDataColumnMutation
} from '../../../state/api';
import { CupTabularDataHeader } from './CupTabularDataHeader';
import { Label } from '../../../types/label';
import { useMirrorContext } from '../../mirror/MirrorContextProvider';
import SectionTable from '../../../components/SectionTable';

export interface CupTabularDataTableProps {
  cup: Cup;
}

interface StyledTableCellProps {
  children: ReactNode;
}

const TableRowHeaderCell = ({ children }: StyledTableCellProps) => {
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: 'white'
      }}
    >
      {children}
    </TableCell>
  );
};

export const CupTabularDataTable = ({ cup }: CupTabularDataTableProps) => {
  const [putCupTabularDataColumn] = usePutCupTabularDataColumnMutation();
  const [postLabel] = usePostLabelMutation();
  const {
    data: exampleData,
    isLoading,
    refetch
  } = useGetCupTabularExampleDataQuery({ id: cup.tabularData.id, cupId: cup.id }, { skip: !cup.tabularData });
  const { availableLabels } = useMirrorContext();
  const [selectedColumn, setSelectedColumn] = useState<CupTabularDataColumn>(null);

  useEffect(() => {
    if (cup.tabularData) {
      refetch();
    }
  }, [cup.id, cup.tabularData?.id, refetch]);

  const handleColumnClick = (column: CupTabularDataColumn) => {
    setSelectedColumn(column);
  };

  const handleCancel = () => {
    setSelectedColumn(null);
  };

  const handleLabelRemove = async (column: CupTabularDataColumn) => {
    await saveColumn(column);
    setSelectedColumn(null);
  };

  const handleLabelCreate = async (column: CupTabularDataColumn, name: string) => {
    const label = await postLabel({
      title: name,
      description: name,
      language: 'en'
    }).unwrap();

    await saveColumn(column, label);
  };

  const saveColumn = async (column: CupTabularDataColumn, label?: Label) => {
    await putCupTabularDataColumn({
      cupId: cup.id,
      tabularDataId: cup.tabularData.id,
      index: column.index,
      labelId: label ? label.labelId : null
    }).unwrap();
    setSelectedColumn(null);
  };

  const columnsState = useMemo(() => {
    if (cup && cup.tabularData && cup.tabularData.columns.length > 0) {
      return [...cup.tabularData.columns].sort((a, b) => a.index - b.index);
    } else {
      return [];
    }
  }, [cup.tabularData]);

  const rowsState = useMemo(() => {
    if (exampleData && exampleData.rows) {
      return exampleData.rows;
    } else {
      return [];
    }
  }, [exampleData]);

  const unselectedLabels = useMemo(() => {
    const usedLabels = columnsState.filter((c) => c.label != null).map((c) => c.label.name);

    return availableLabels.filter((l) => !usedLabels.includes(l.name));
  }, [availableLabels, columnsState]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex="1">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SectionTable>
      <TableHead>
        <TableRow>
          <TableCell>
            <Box>Label</Box>
            <Box>Header</Box>
          </TableCell>
          {columnsState.map((column) => (
            <CupTabularDataHeader
              key={column.index}
              column={column}
              selected={selectedColumn && column.originalName === selectedColumn.originalName}
              onColumnClick={(column) => handleColumnClick(column)}
              availableLabels={unselectedLabels}
              onLabelChange={(label) => saveColumn(column, label)}
              onLabelRemove={() => handleLabelRemove(column)}
              onLabelCreate={(name) => handleLabelCreate(column, name)}
              onCancel={handleCancel}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowsState.map((row) => (
          <TableRow key={row.rowNumber} hover>
            <TableRowHeaderCell>{row.rowNumber}</TableRowHeaderCell>
            {row.cells.map((cell, index) => (
              <TableCell key={index}>{cell.value ? cell.value : ''}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </SectionTable>
  );
};
