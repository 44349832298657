import React from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Checkbox, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ExportFormatColumn, TaskDefinition, TaskExportConfig } from '../../../types/task';
import SelectableLabel from '../../../components/SelectableLabel';
import { getInputParameter } from '../../../types/utils/workflows';
import { DragIndicator } from '@mui/icons-material';
import SectionTable from '../../../components/SectionTable';
import { useMirrorContext } from '../MirrorContextProvider';

interface SortableTableCellProps {
  name: string;
  task: TaskDefinition;
  mappedBy: string;
  onDelete: () => void;
  onSelectColumn: () => void;
  selected: boolean;
}

const SortableTableRow = ({ name, task, mappedBy, onDelete, onSelectColumn, selected }: SortableTableCellProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: mappedBy });
  const { getTaskByOutputParameter } = useMirrorContext();

  const input = getInputParameter(task, mappedBy);
  const mappedTask = input && getTaskByOutputParameter(input.mappedBy);

  const transformOnlyTranslate = transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '';

  const style: React.CSSProperties = {
    transform: transformOnlyTranslate,
    transition,
    opacity: 1
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell>
        <DragIndicator {...listeners} {...attributes} />
        <Checkbox size="small" checked={selected} onChange={() => onSelectColumn()} />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        <SelectableLabel
          name={name}
          onClick={() => onSelectColumn()}
          selected={selected}
          onDelete={onDelete}
          fulfilled={input && input.mappedBy && input.mappedBy.length > 0}
        />
      </TableCell>
      <TableCell>{mappedTask && mappedTask.name}</TableCell>
    </TableRow>
  );
};

export interface MirrorExportTaskColumnEditorProps {
  task: TaskDefinition;
  columns: ExportFormatColumn[];
  exportConfig: TaskExportConfig;
  isColumnSelected: (config: ExportFormatColumn) => boolean;
  onSaveConfig: (updated: ExportFormatColumn[]) => void;
  onColumnDelete: (config: ExportFormatColumn) => void;
  onColumnClick: (config: ExportFormatColumn) => void;
}

export const MirrorExportTaskColumnEditor = ({
  task,
  columns,
  onColumnClick,
  onColumnDelete,
  isColumnSelected,
  onSaveConfig
}: MirrorExportTaskColumnEditorProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((column) => column.mappedBy === active.id);
      const newIndex = columns.findIndex((column) => column.mappedBy === over.id);

      const newColumns = columns.map((item, index) => {
        if (index === newIndex) {
          return { ...item, index: columns[oldIndex].index };
        } else if (index === oldIndex) {
          return { ...item, index: columns[newIndex].index };
        }
        return item;
      });
      onSaveConfig(newColumns);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={columns.map((column) => column.mappedBy)} strategy={verticalListSortingStrategy}>
        <SectionTable>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Export Header</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>From</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columns.map((column) => (
              <SortableTableRow
                key={column.index}
                task={task}
                name={column.name}
                mappedBy={column.mappedBy}
                onDelete={() => onColumnDelete(column)}
                selected={isColumnSelected(column)}
                onSelectColumn={() => onColumnClick(column)}
              />
            ))}
          </TableBody>
        </SectionTable>
      </SortableContext>
    </DndContext>
  );
};
